import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../config";
import ToastBox from "../../common/Toast/ToastBox";
import { useSelector } from "react-redux";
const DeleteDriver = (props) => {
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseType, setResponseType] = useState("");
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };

    function submitData(DriverId) {
        setLoading(true);
        const deleteDriverMutation = {
            query: `
                mutation( $firm_id: ID!,$driver_id: ID!) {
                    deleteDriver(firm_id: $firm_id,driver_id:$driver_id) {
                        type
                        message
                        error
                    }
                }`,
            variables: {
                firm_id: +props?.firmId,
                driver_id: +DriverId,
            },
        };

        axios.post(BASE_URL, deleteDriverMutation, { headers })
            .then((response) => {
                if (response?.data?.data?.deleteDriver?.type === "success") {
                    setTimeout(() => {
                        props.handleClose(false)
                    }, 4000)
                }
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        if (responseType) {
            setTimeout(() => {
                setResponseType("");
            }, 3000);
        }
    }, [responseType]);

    return (
        <Modal centered show={props?.show} onHide={() => props.handleClose(false)} className="modal-dialog1">
            <Modal.Title className="modal.title1" children>
                Are you sure you would like to delete ?
            </Modal.Title >
            <div className="d-flex modal-btn align-items-center justify-content-between mb-3">
                <button onClick={() => submitData(props?.driverForDelete)}>
                    {loading ? "...Loading" : " Delete"}
                </button>
                <span className="cancel-btn" onClick={() => props.handleClose(false)}>
                    Cancel
                </span>
            </div>
            {responseMessage && responseType && (
                <ToastBox
                    responseMessage={responseMessage}
                    responseType={responseType}
                />
            )}
        </Modal>
    );
};

export default DeleteDriver;
