import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from 'react-bootstrap/Dropdown';
import '../Header/Header.css';
import { Button, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFirmDetails, setLogout } from "../../../features/Login";
import { instance } from "../../../axioConfig";
import Spinner from 'react-bootstrap/Spinner';
import { useEffect, useState } from "react";
import axios from "axios";
import { FormControl } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import { BASE_URL } from "../../../config";

function Header() {
  const reload = useSelector((state) => state?.loginDetail?.reload)
  const userId = useSelector((state) => state?.loginDetail?.userDetail?.id);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [loading, setLoading] = useState(false);
  const [firmLoading, setFirmLoading] = useState(true)
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [firm, setFirm] = useState([]);
  const [firmDetail, setFirmDetail] = useState("")
  const [test, setTest] = useState(false);
  const [options, setOption] = useState("")
  const headers = {
    'Authorization': `Bearer ${bearerToken}`,
    'Content-Type': 'application/json'
  };
  // ===== Logout Mutation =======
  function logout() {
    setLoading(true);

    const query = {
      query: `mutation{
        logout{
          message
        }
      }`
    }

    instance.post("", query, { headers })
      .then(function (response) {
        dispatch(setLogout());
        Navigate("/");

      })
      .catch(function (error) {
      });
  }

  // ====== Query to get Firms ========

  useEffect(() => {

    const query = {
      query: `query($user_id:ID!,$isFirmDeleted:Boolean!){
        firm(user_id:$user_id , isFirmDeleted:$isFirmDeleted){
          firm_id
          firm_name
          lr_prefix
          lr_suffix
          firm_contact
          firm_gstin
        }
      }`,
      variables: {
        user_id: userId,
        isFirmDeleted: false
      }
    }

    axios.post(BASE_URL, query, { headers })
      .then(response => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout())
        }
        if (response.data.data?.firm) {
          setFirm(response.data?.data.firm);
          setTest(true);
          setFirmDetail(response?.data.data.firm[0])
          setFirmLoading(false);
        }
      })
      .catch(error => {
        setFirmLoading(false)
      });

  }, [reload])


  useEffect(() => {
    if (test) {
      if (firm.length == 0) {
        Navigate("/addfirms")
      }
    }
  }, [test])

  useEffect(() => {
    const selectFirmOption = JSON.parse(localStorage.getItem("selectedOption"))
    setOption(selectFirmOption)
    if (selectFirmOption) {
      setOption(selectFirmOption);
      dispatch(setFirmDetails(selectFirmOption))
    }
    else {
      dispatch(setFirmDetails(firm?.[0]))
    }
  }, [firmDetail, options])

  const handleSelect = (event) => {

    const selectedfirm = firm?.find(
      (option) => option.firm_id == event.target.value
    );
    setOption(selectedfirm)
    localStorage.setItem("selectedOption", JSON.stringify(selectedfirm))
    setFirmDetail(selectedfirm)
  }

  return (

    <header>
      <Navbar variant="light" expand="lg">
        <Container fluid >

          <Link to="/">
            <Navbar.Brand>
              <img src="/logo.png" className="img-fluid site-logo" />
            </Navbar.Brand>
          </Link>


          <div className="d-flex ">
            <FormControl className="mt-3 me-3" variant="standard" sx={{ m: 0, minWidth: 200 }}>

              <NativeSelect
                id="select"
                value={options?.firm_id}
                onChange={handleSelect}
              >
                {firmLoading ? <option className="p-2">...Loading</option>
                  :

                  (firm?.length

                    ?
                    firm?.map((firm) => <option value={firm.firm_id}>{firm.firm_name}</option>)
                    :
                    <>
                      <option>Register your firm</option>

                    </>
                  )
                }
              </NativeSelect>
            </FormControl>

            <Dropdown className="d-inline  mt-2 "  >
              <Dropdown.Toggle className="dark-btn" id="dropdown-basic" data-bs-toggle="dropdown">
                <i className="bi bi-person-fill"></i><span className="p-1"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item ><Link to="/profile"><i className="bi bi-person-check red-text"></i> &nbsp; Profile</Link></Dropdown.Item>
                <Dropdown.Item ><Link to="/firms"><i className="bi bi-buildings red-text"></i> &nbsp; Firms</Link></Dropdown.Item>
                <Dropdown.Item>
                  <Button className="dark-btn " onClick={() => logout()}>
                    {loading &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    }
                    Logout
                  </Button>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown >
          </div>

        </Container>
      </Navbar>
    </header>
  );
}

export default Header;


