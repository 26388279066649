import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { BASE_URL } from '../../../config';

const ClosedTripView = (props) => {
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [invoiceContent, setInvoiceContent] = useState();
    const [truckTrip, setTruckTrip] = useState();
    const [invoicePayment, setInvoicePayment] = useState();
    const headers = {
        Authorization: `Bearer ${bearerToken} `,
        "Content-Type": "application/json",
    };
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    useEffect(() => {
        const query = {
            query: `query($trip_id:ID!){
                tripInvoice(trip_id:$trip_id) {
                    trip_id
                    trip_invoice_id
                    package_quantity
                    package_quantity
                    total_weight
                    rate
                    freight
                    total_amount
                    remaining
                    lr_number
                  }
              } `,
            variables: {
                trip_id: props?.tripId
            }
        }

        const query2 = {
            query: `query($trip_id:ID!){
                truckTrip(trip_id:$trip_id){
                  trip_date
                  trip_consigner
                  trip_consignee
                  trip_product
                  trip_source
                  trip_destination
                  trip_invoice_number
                  truck{
                    truck_number
                  }
                }
              }`,
            variables: {
                trip_id: props?.tripId
            }
        }
        // ======= query to get Trip Invoice ========

        axios
            .post(BASE_URL, query, { headers })
            .then((response) => {
                setInvoiceContent(response?.data?.data.tripInvoice?.[0]);

                const query3 = {
                    query: `query($trip_invoice_id:ID!){
                        tripInvoicePayment(trip_invoice_id:$trip_invoice_id){
                            amount_paid
                        }
                    }`,
                    variables: {
                        trip_invoice_id: response?.data?.data.tripInvoice?.[0]?.trip_invoice_id
                    }
                }
                axios
                    .post(BASE_URL, query3, { headers })
                    .then((response) => {
                        setInvoicePayment(response.data?.data.tripInvoicePayment)
                    })
                    .catch((error) => {
                    })

            })
            .catch((error) => [
            ])

        // ======= query to get Trip details ========

        axios
            .post(BASE_URL, query2, { headers })
            .then((response) => {
                setTruckTrip(response?.data.data.truckTrip?.[0])
            })
            .catch((error) => [
            ])

    }, [props])

    return (<>
        <Modal show={props?.modalHandler} size="lg" scrollable={true} >
            <Modal.Header
                className='mb-4'
                style={{
                    backgroundColor: "#10233c",
                    color: "white",
                    height: "40px",
                    textAlign: "center",
                }}
            >
                <h4 className=" my-0 m-auto">Closed Trip Details</h4>

            </Modal.Header>
            <Modal.Body>
                {truckTrip && invoiceContent && invoicePayment
                    ?
                    <Form>
                        <Form.Group className="form-group">
                            <Form.Label>Trip ID</Form.Label>
                            <Form.Control disabled defaultValue={invoiceContent?.trip_id}
                                type="text"
                            />
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>LR Number</Form.Label>
                            <Form.Control disabled defaultValue={invoiceContent?.lr_number}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Consigner</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_consigner}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Consignee</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_consignee}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Source</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_source}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Destination</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_destination}
                                type="text"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Product</Form.Label>
                            <Form.Control disabled defaultValue={truckTrip?.trip_product}
                                type="text"
                            />
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Total Payable Amount</Form.Label>
                            <div className="d-flex">
                                <div className="py-2 px-3 form_icon">
                                    <i className="bi bi-currency-rupee"></i>
                                </div>
                                <Form.Control className='form-control_icon' disabled defaultValue={invoiceContent?.total_amount}
                                    type="text"
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Remaining Amount</Form.Label>
                            <div className="d-flex">
                                <div className="py-2 px-3 form_icon">
                                    <i className="bi bi-currency-rupee"></i>
                                </div>
                                <Form.Control className='form-control_icon' disabled defaultValue={invoiceContent?.remaining}
                                    type="text"
                                    {...register('remaining', {

                                    })
                                    }
                                />
                            </div>

                        </Form.Group>
                        <div style={{ clear: "both" }} ></div>

                    </Form>
                    :
                    <div className='m-5 p-5 ' >
                        <SimpleBackdrop />
                    </div>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={() => props?.modalAction(false)}>
                    Close
                </Button>
            </Modal.Footer>

        </Modal>

    </>

    )
}

export default ClosedTripView