import React, { useEffect, useState } from 'react'
import { City } from "country-state-city";
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { setSelectedSource } from '../../../features/FilterComponents';

const SourceFilter = () => {
  const [source, setSource] = useState();
  const dispatch = useDispatch();
  const cities = City.getCitiesOfCountry("IN");
  const CitiesOption = cities?.map((c) => {
    return { value: c.name, label: c.name }
  })

  useEffect(() => {
    dispatch(setSelectedSource(source))
  }, [source])

  const handleSourceSelect = (e) => {
    setSource(e?.value)
  }
  return <>
    <CreatableSelect
      required
      className="basic-single w-100 pe-2"
      classNamePrefix="select"
      isClearable={true}
      isSearchable={true}
      onChange={handleSourceSelect}
      options={CitiesOption}
    />
  </>

}

export default SourceFilter