import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { setSelectedInvoiceNumber } from '../../../features/FilterComponents';
import { BASE_URL } from '../../../config';

const InvoiceNumberFilter = () => {
    const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [tripInvoiceNumber, setTripInvoiceNumber] = useState();
    const [invoiceNumber, setInvoiceNumber] = useState();
    const dispatch = useDispatch();
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };
    useEffect(() => {
        dispatch(setSelectedInvoiceNumber(invoiceNumber))
    }, [invoiceNumber])

    useEffect(() => {
        // ==== Query to get Invoice Number and LR Number ========
        const InvoiceQuery = {
            query: `query($firm_id:ID!,$limit:Int){
                firmInvoice(firm_id:$firm_id,limit:$limit) {
                truckTrip {
                    trip_invoice_number
                }
                }
                }`,
            variables: {
                firm_id: firm_id,
                limit: 3
            }
        }
        axios.post(BASE_URL, InvoiceQuery, { headers })
            .then(response => {
                setTripInvoiceNumber(response.data?.data.firmInvoice?.map((item) => {
                    return { value: item.truckTrip.trip_invoice_number, label: item.truckTrip.trip_invoice_number }
                }))
            })
            .catch(error => {
            })
    }, [firm_id])

    const filterInvoiceNumber = (inputValue) => {
        const query = {
            query: `query($firm_id:ID,$inputInvoice:String){
            filterInvoiceNumber(firm_id:$firm_id, inputInvoice:$inputInvoice) {
              truckTrip {
                trip_invoice_number
              }
            }
          }`,
            variables: {
                firm_id: firm_id,
                inputInvoice: inputValue
            }
        }

        return axios.post(BASE_URL, query, { headers })
            .then((response) => {
                return response.data.data?.filterInvoiceNumber?.map((item) => {
                    return { value: item.truckTrip.trip_invoice_number, label: item.truckTrip.trip_invoice_number }
                })
            })
            .catch((error) => {

            })

    }
    const queryOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterInvoiceNumber(inputValue));
            }, 1000);
        });

    const handleInvoiceNumberSelect = (e) => {
        setInvoiceNumber(e?.value);
    }

    return (<>
        <AsyncSelect
            className="basic-single w-100 pe-2"
            cacheOptions
            isClearable
            defaultOptions={tripInvoiceNumber}
            loadOptions={queryOptions}
            onChange={handleInvoiceNumberSelect}
        />

    </>)

}
export default InvoiceNumberFilter