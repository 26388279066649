import React from 'react'

const PrivacyPolicy = () => {
    return <>
        <div className="container  mt-5">
            <div className="col-lg-8 offset-lg-2">
                <h3>Privacy Policy</h3>
                <p><strong>Effective Date:</strong> 2023-11-09</p>
                <hr />
                <p>Smashing InfoLabs Pvt. Ltd. built the Billing Wheels app as a Free app. This SERVICE is provided by Smashing InfoLabs Pvt. Ltd. at no cost and is intended for use as is.</p>

                <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use our Service.</p>

                <h5>1. Information We Collect</h5>

                <p>We may collect and process the following types of information:</p>

                <ul>
                    <li><strong>Personal Information:</strong> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to, your name, email address, phone number, and other relevant information.</li>
                </ul>

                <h5>2. Use of Information</h5>

                <p>The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

                <h5>3. Data Sharing</h5>

                <p>We will not sell, rent, or trade your Personal Information to any third parties.</p>

                <h5>4. Security</h5>

                <p>We are committed to ensuring that your information is secure. We have implemented suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect.</p>

                <h5>5. Data Retention and Deletion</h5>

                <p>We will retain your Personal Information only for as long as necessary for the purposes set out in this Privacy Policy. We will take reasonable steps to delete or anonymize personal information that is no longer needed.</p>

                <h5>7. Changes to This Privacy Policy</h5>

                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                <h5>8. Contact Us</h5>

                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:er.sourabhbhatt@gmail.com">er.sourabhbhatt@gmail.com</a>.</p>
                <hr />
                <p>By using our Service, you agree to the terms outlined in this Privacy Policy.</p>

            </div>
        </div>
    </>
}

export default PrivacyPolicy