
import React, { useState, useEffect } from "react";
import '../Verification/Verification.css';
import '../../common/Form/Form.css';
import { instance } from "../../../axioConfig";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setTempToken } from "../../../features/Login";
import { useForm } from "react-hook-form";
import Login from "../Login/Login";
import SimpleBackdrop from "../../common/Spinner/Spinner";

import { useNavigate } from "react-router";


function Verification() {

  const tempToken = useSelector((state) => state?.loginDetail?.tempToken);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(30);
  const [loading, setLoading] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000); return () => clearInterval(timer);
  }, [counter]);

  const { register, setValue, handleSubmit, formState: { errors } } = useForm({
    mode: "all"
  });

  const resendOTP = (event) => {
    event.currentTarget.disabled = false;
    setCounter(30);

    const query = {
      query: `mutation($tempToken:String!){
        resendOtp(tempToken:$tempToken){
          message
          tempToken
          redirectToLogin
          error{
            message
          }
        }
      }`,
      variables: {
        "tempToken": tempToken
      }
    }

    instance.post('', query
    )
      .then(function (response) {
        if (response.status == 200 && response.data?.data.resendOtp.message == "OTP Sent Successfully") {
          dispatch(setTempToken(response.data.data.resendOtp.tempToken));
        }
        else {
          setRedirectToLogin(response.data?.redirectToLogin)
        }
      })
      .catch(function (error) {

      });
  }
  const onSubmit = (data) => {
    const query = {
      query: `mutation ($otp:Int! , $tempToken:String!){
        verifyOtp(otp:$otp,tempToken:$tempToken){
          message
          userDetail{
            id 
            name
            email
            mobile
            created_at
            updated_at
            email_verified_at
          }
          token
          error{
            message
          }
        }
      }`,
      variables: {
        "otp": +(data.otp),
        "tempToken": tempToken
      }
    }
    setLoading(true)

    instance.post('', query
    ).then(function (response) {

      if (response.status == 200 && response.data?.data.verifyOtp.message
        == "Verification Successfull") {
        dispatch(setLogin(response.data?.data.verifyOtp));
        setLoading(false);
        Navigate("/");

      }
      else {
        if (response.data?.data.verifyOtp.error) {
          setResponseError(response.data?.data.verifyOtp.error.message)
          setLoading(false);

        } else {
          setLoading(false);
          setRedirectToLogin(response.data?.data.redirectToLogin)

        }
      }
    })
      .catch(function (error) {

      });
  }
  const handleCapture = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("otp", result);
  };

  if (redirectToLogin) {

    return <Login />

  }

  return (
    <>
      {loading ?
        <SimpleBackdrop /> :
        <div className="container-fluid otp-verification">
          <div className="row">
            <div className="col-lg-5 left d-flex justify-content-center align-items-center">
              <img
                src="/logo.png"
                className="img-fluid site-logo d-block mx-auto"
              />

            </div>

            <div className="col-lg-7 mt-5 mt-lg-0 right d-flex justify-content-center align-items-center">
              <h2 className="mb-4 red-text text-center">Verification</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h4 className="text-center mb-3">Please enter the OTP sent to your email to confirm your account</h4>
                <div className="form-group mb-4 w-100">
                  <input className="form-control"
                    name="otp"
                    id="OTP"
                    onChangeCapture={handleCapture}

                    aria-describedby="emailHelp"
                    placeholder="Enter OTP"
                    {...register("otp", {
                      required: true,
                      pattern: /^[0-9]*$/,
                      minLength: 6,
                      maxLength: 6,
                    })} />
                  {errors?.otp?.type === "required" && <span className="error_p">OTP is required</span>}
                  {errors?.otp?.type === "minLength" && <span className="error_p">OTP length should be six digits </span>}
                  {errors?.otp?.type === "pattern" && <span className="error_p">OTP should only include number</span>}
                  {errors?.otp?.type === "maxLength" && <span className="error_p">OTP length should be six digits</span>}
                  {responseError ? <span className=" w-100 d-block my-5 alert alert-danger text-center">{responseError}</span> : null}
                </div>


                {(counter > 0) ?
                  <>
                    <div className="d-flex justify-content-between px-3">
                      <button type="submit"
                        className="d-block btn dark-btn btn-lg"
                        id="verify-otp">
                        Verify Otp
                      </button>
                      <button onClick={resendOTP} disabled="true" className="verfi-code1">Resend OTP </button>

                    </div>
                    <div className="text-center mt-3">Resend OTP after <span className="text-danger"> {counter}  </span>seconds</div>
                  </>
                  :
                  <div className="d-flex justify-content-between px-3 ">
                    <button type="submit"
                      className="d-block btn dark-btn btn-lg"
                      id="verify-otp">
                      Verify Otp
                    </button>
                    <button className="verfi-code1" onClick={resendOTP}>  Resend OTP  </button>


                  </div>}
                <div className="d-flex justify-content-center error_p mt-3 server_error ">
                  {responseError?.message ? responseError.message[0] : ""}<br />

                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </>
  );
}


export default Verification;