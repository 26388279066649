import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../../config";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import { capitalizeInput } from "../../common/FunctionsC/functionsC";
import Loader from "../../common/Spinner/Loader";

function AddConsignee() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const firmId = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const [messageType, setMessageType] = useState("");
  const [consigneeError, setConsigneeError] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    setLoading(true)
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
    };

    const query = {
      query: `mutation($firm_id:ID!,$consignee_name:String!,$consignee_email:String!,$consignee_contact:String!,$consignee_pancard:String!,$consignee_gstin:String!,$consignee_address:String!){
            addConsignee(firm_id:$firm_id,consignee_name:$consignee_name,consignee_email:$consignee_email,consignee_contact:$consignee_contact,consignee_pancard:$consignee_pancard,consignee_gstin:$consignee_gstin,consignee_address:$consignee_address){
             error{
              consignee_name
              consignee_email
              consignee_contact
              consignee_pancard
              consignee_gstin
              consignee_address
            }
              message
              type
              
            }
          }`,
      variables: {
        firm_id: firmId,
        consignee_name: data.consignee_name,
        consignee_email: data.consignee_email,
        consignee_contact: data.consignee_contact,
        consignee_pancard: data.consignee_pancard,
        consignee_gstin: data.consignee_gstin,
        consignee_address: data.consignee_address,
      },
    };

    axios
      .post(BASE_URL, query, { headers })
      .then((response) => {
        if (response.data?.data.addConsignee.type == "success") {
          setLoading(false)
          setMessageType(response.data?.data.addConsignee.type);
          setShow(true);
          setConsigneeError(response.data?.data.addConsignee.message);
          reset();
        }
        if (response.data?.data.addConsignee.type == "failed") {
          setLoading(false)
          setMessageType(response.data?.data.addConsignee.type);
          setShow(true);
          if (response.data.data.addConsignee.error.consignee_contact != null)
            setConsigneeError(
              response.data.data.addConsignee.error.consignee_contact
            );
          else if (
            response.data.data.addConsignee.error.consignee_gstin != null
          )
            setConsigneeError(
              response.data.data.addConsignee.error.consignee_gstin
            );
          else if (
            response.data.data.addConsignee.error.consignee_email != null
          )
            setConsigneeError(
              response.data.data.addConsignee.error.consignee_email
            );
          else if (
            response.data.data.addConsignee.error.consignee_pancard != null
          )
            setConsigneeError(
              response.data.data.addConsignee.error.consignee_pancard
            );
        }
      })
      .catch((error) => { });

  };

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
      }, 5000);
    }
  }, [show, firmId]);


  const handleCapture = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("consignee_contact", result);
  };

  return (
    <>
      <form className="addtruck-form mt-5 " onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label for="">Consignee Name <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
          <div className="d-flex">
            <div className="py-2 px-3 form_icon_icon font-weight-bold">
              <i className="bi bi-person-add"></i>
            </div>
            <input
              type="text"
              onInput={(e) => capitalizeInput(e)}
              className="form-control_icon"
              id="name"
              {...register("consignee_name", {
                required: true,
              })}
            />
          </div>
          {errors?.consignee_name?.type === "required" && (
            <p className="error_p">Consignee name is required</p>
          )}
        </div>
        <div className="form-group">
          <label type="text" for="">
            Contact
            <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
          <input
            type="contact"
            onChangeCapture={handleCapture}
            className="form-control"
            id=""
            {...register("consignee_contact", {
              required: true,
              pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            })}
          />
          {errors?.consignee_contact?.type === "required" && (
            <p className="error_p">Contact number is required</p>
          )}
          {errors?.consignee_contact?.type === "pattern" && (
            <p className="error_p">Please entre a valid Contact number</p>
          )}
        </div>
        <div className="form-group">
          <label for="">Email <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
          <input
            type="email"
            className="form-control"
            id=""
            {...register("consignee_email", {
              required: true,
            })}
          />
          {errors?.consignee_email?.type === "required" && (
            <p className="error_p">Email is required</p>
          )}
        </div>
        <div className="form-group">
          <label for="">Address <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
          <input
            type="text"
            className="form-control"
            onInput={(e) => capitalizeInput(e)}
            id=""
            {...register("consignee_address", {
              required: true,
              minLength: 5,
            })}
          />
          {errors?.consignee_address?.type === "required" && (
            <p className="error_p">Address is required</p>
          )}
          {errors?.consignee_address?.type === "minLength" && (
            <p className="error_p">Address must be atleast 5 character</p>
          )}
        </div>
        <div className="form-group">
          <label for="">GST No. <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
          <input
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
            type="text"
            className="form-control"
            id=""
            {...register("consignee_gstin", {
              required: true,
            })}
          />
          {errors?.consignee_gstin?.type === "required" && (
            <p className="error_p">GST is required</p>
          )}
        </div>
        <div className="form-group">
          <label for="">PAN  <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
          <input
            type="text"
            className="form-control"
            id=""
            onInput={(e) =>
              (e.target.value = ("" + e.target.value).toUpperCase())
            }
            {...register("consignee_pancard", {
              required: true,
              minLength: 10,
              maxLength: 10,
              pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
            })}
          />
          {errors?.consignee_pancard?.type === "pattern" && (
            <p className="error_p">Please entre valid PAN number </p>
          )}
          {errors?.consignee_pancard?.type === "maxLength" && (
            <p className="error_p">PAN number cannot exceed 10 characters</p>
          )}
          {errors?.consignee_pancard?.type === "minLength" && (
            <p className="error_p">
              PAN number cannot be less than 10 characters
            </p>
          )}
          {errors?.consignee_pancard?.type === "required" && (
            <p className="error_p">PAN card number is required</p>
          )}
        </div>
        <div className="mt-5">
          <button type="submit" className="btn dark-btn mx-auto d-block mt-3">
            {loading ? <> <Loader /> {" "}  Submitting...</> : <>Submit</>}
          </button>
        </div>

        {show && (
          <CustomizedSnackbars
            show={show}
            message={consigneeError}
            messageType={messageType}
          />
        )}
      </form>
    </>
  );
}

export default AddConsignee;
