import { configureStore } from '@reduxjs/toolkit';
import driverFormReducer from '../features/DriverForm';
import login from '../features/Login';
import truckFormReducer from '../features/TruckForm';
import FilterComponents from '../features/FilterComponents';

export const store = configureStore({
  reducer: {
    loginDetail: login,
    driverFormReducer: driverFormReducer,
    truckFormReducer: truckFormReducer,
    filterComponents: FilterComponents
  },
});
