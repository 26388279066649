import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../config";
import ToastBox from "../../common/Toast/ToastBox";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
const DeleteTruck = (props) => {
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseType, setResponseType] = useState("");
    const [showModal, setShowModal] = useState(false);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    }

    useEffect(() => {
        if (responseType) {
            setTimeout(() => {
                setResponseType("");
                setResponseMessage("");
            }, 3000);
        }
    }, [responseType]);

    function submitData(truckId) {
        setLoading(true);
        const deleteTruckMutation = {
            query: `
                mutation($truck_id: ID!, $firm_id: ID!) {
                    deleteTruck(truck_id: $truck_id, firm_id: $firm_id) {
                        type
                        message
                        error
                    }
                }`,
            variables: {
                firm_id: +props?.firmId,
                truck_id: +truckId,
            },
        };

        axios.post(BASE_URL, deleteTruckMutation, { headers })
            .then((response) => {
                console.log("response", response)
                setLoading(false);
                setResponseType(response?.data?.data?.deleteTruck?.type)
                if (response?.data?.data?.deleteTruck?.type === "success") {
                    setShowModal(true)
                    setResponseMessage(response?.data?.data?.deleteTruck?.message)
                } else {
                    setShowModal(true)
                }
                setTimeout(() => {
                    props.handleClose(false)
                    setShowModal(false)
                }, 4000)
            })
            .catch((error) => {
            })
    }

    return (
        <>
            <Modal centered show={props?.show} onHide={() => props.handleClose(false)} className="modal-dialog1">
                <Modal.Title className="modal-dialog1" children>
                    Are you sure you would like to delete ?
                </Modal.Title>
                <div className="d-flex modal-btn align-items-center justify-content-between mb-3">
                    <button onClick={() => submitData(props?.truckForDelete)}>
                        {loading ? "...Loading" : " Delete"}
                    </button>
                    <span className="cancel-btn" onClick={() => props.handleClose(false)}>
                        Cancel
                    </span>
                </div>

            </Modal>
            {showModal && (
                <CustomizedSnackbars
                    show={showModal}
                    message={responseMessage}
                    messageType={responseType}
                />
            )}
        </>
    );
};

export default DeleteTruck;
