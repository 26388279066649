import React, { useEffect, useState } from "react";
import "../Builty/Builty.css";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
const Builty = (props) => {
  const firmDetails = useSelector((state) => state?.loginDetail?.firmDetails);

  const handlePdf = async () => {
    const pdf = new jsPDF("landscape", "pt", "a4");
    const data = await document.querySelector("#pdf");

    pdf.html(data).then(() => {
      pdf.save(`Builty${props?.invoice_no}.pdf`);
    });
  };

  return (
    <>
      <div id="pdf">
        <table className="showtable">
          <tbody>
            <tr>
              <td className="col-lg-9" rowSpan="4" colSpan="3">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="w-50 ">
                    <h4 className="mt-4">{firmDetails?.firm_name}</h4>
                  </div>
                  {/* <div className="mx-1 w-50">
                              <div className="d-flex"><p>Reg.Off. </p>  : Lorem ipsum, dolor sit amet aperiam. Quisquam odio libero similique.</div>
                              <div className="d-flex"><p>Br.Off. </p> : Lorem ipsum, dolor sit amet aperiam. Quisquam odio libero similique.</div>
                           </div> */}
                </div>
              </td>

              <td className="col-lg-4 element-left">CIN : { }</td>
            </tr>
            <tr>
              <td className="element-left">
                GSTIN : {firmDetails?.firm_gstin}
              </td>
            </tr>
            <tr>
              <td className="element-left">SAP Code : { }</td>
            </tr>
            <tr>
              <td className="element-left">
                Contact : {firmDetails?.firm_contact}
                <br />
              </td>
            </tr>

            <tr>
              <td className="element-left col-lg-4 " rowSpan="3">
                <div className="d-flex flex-column justify-content-between">
                  <p>
                    Consignor's Name & Address :<br />
                    {props?.Consigner}
                  </p>
                  <p className="m-0 p-0">GSTIN : {props?.consignerGst}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td className="element-left col-lg-4 " rowSpan={2} colSpan={2}>
                <div className="d-flex flex-column justify-content-between">
                  <p>
                    Consignee's Name & Address :<br />
                    {props?.Consignee}
                  </p>
                  <p className="m-0 p-0">GSTIN : {props?.consigneeGst}</p>
                </div>
              </td>

              <td className="element-left">L.R. No. : {props?.Lr}</td>
            </tr>

            <tr>
              <td className="element-left">
                Date : {props?.trip_date?.split("-").reverse().join("-")}
              </td>
            </tr>
            <tr>
              <td className="element-left">From : {props?.source}</td>

              <td className="element-left">To : {props?.Destination}</td>
              <td className="element-left " colSpan={2}>
                DI No.:
              </td>
            </tr>
            <tr>
              <td className="element-left">Vehicle No. : {props?.truck_no}</td>
              <td className="element-left">Bags : {props?.bags}</td>
              <td className="element-left " colSpan={2} rowSpan={2}>
                Invoice No. : {props?.invoice_no}
              </td>
            </tr>
            <tr>
              <td className="element-left" colSpan={3}>
                Whether tax is payable Under reverse charge mechanism:
              </td>
            </tr>
            <tr>
              <td className="element-left" colSpan={3}>
                {" "}
                Acknowledgement consignee with signatura & stamp
              </td>
              <td className="element-left">Product : {props?.product}</td>
            </tr>
            <tr className="">
              <td className="element-left" colSpan="5" rowSpan="3">
                Signature :
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <button onClick={handlePdf}>Download PDF</button> */}
    </>
  );
};

export default Builty;
