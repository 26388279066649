import React from "react";
import "../BuyPlans/BuyPlans.css";
const BuyPlans = () => {
  const isAndroid =
    typeof window !== "undefined" &&
    window.navigator.userAgent.toLowerCase().includes("android");

  const handleClick = () => {
    const rzpOptions = {
      key: "rzp_test_x7MfFcDfJCjfwX", // Enter the Key ID generated from the Dashboard
      amount: 20000, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Liter",
      order_id: "order_M6w3CbqnkyAyCK", // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      prefill: {
        name: "Sourav",
        email: "bankeyritu@gmail.com",
        contact: "5464543544",
      },
      notes: {
        address: "indore",
        orderDetail: "nokfd",
      },
    };

    if (isAndroid) {
      RazorpayCheckout.open(rzpOptions); /* eslint-disable-line */
    } else {
      const rzp1 = new window.Razorpay(rzpOptions);
      rzp1.open(); /* eslint-disable-line */
    }
  };

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-evenly mt-5 plancard">
          <div className=" w-25 plancard1">
            <div className="topCard ">
              <p className="pt-4">Basic</p>

              <p className="fs-2 fw-bold mt-3">₹ 1800</p>
              <p className="pb-4">1 Month</p>
            </div>

            <div className="bottomcard  w-75  ">
              <ul>
                <li>Add Upto 15 Trucks</li>
                <li>30 Days Plan</li>
                <li>₹4/day for 1 Truck</li>
                <li>
                  <button
                    id="handlePay"
                    className="mt-5  mb-4  buynow"
                    onClick={handleClick}
                  >
                    Buy Now
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className=" w-25 plancard2">
            <div className="topCard ">
              <p className="pt-4">Premium</p>

              <p className="fs-2 fw-bold mt-3">₹32850</p>
              <p className="pb-4">1 Year</p>
            </div>

            <div className="bottomcard  w-75">
              <ul>
              <li>Add Upto 30 Trucks</li>
                <li>365 Days Plan</li>
                <li>₹3/day for 1 Truck</li>
                <li>
                  <button className="mt-5 mb-4 buynow">Buy Now</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyPlans;
