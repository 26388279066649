import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { setSelectedConsignee } from '../../../features/FilterComponents';
import { BASE_URL } from '../../../config';

const ConsigneeFilter = () => {
    const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [tripConsignee, setTripConsignee] = useState();
    const [consignee, setConsignee] = useState();
    const dispatch = useDispatch();
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        dispatch(setSelectedConsignee(consignee))
    }, [consignee])

    useEffect(() => {
        const consigneeQuery = {
            query: `query($firm_id:ID,$limit:Int)
        {
          consignee(firm_id:$firm_id,limit:$limit)
          {
            consignee_name
          }
        }`,
            variables: {
                firm_id: firm_id,
                limit: 3
            }
        }
        axios.post(BASE_URL, consigneeQuery, { headers })
            .then(response => {
                setTripConsignee(response?.data?.data.consignee.map((c) => {
                    return { value: c.consignee_name, label: c.consignee_name }
                }))

            })
            .catch(error => {
            })

    }, [firm_id])

    const filterConsignee = (inputValue) => {
        const query = {
            query: `query($firm_id:ID,$input:String){
            firmConsignee(firm_id:$firm_id,input:$input)
            {
              consignee_name
            }
          }`,
            variables: {
                firm_id: firm_id,
                input: inputValue
            }
        }

        return axios.post(BASE_URL, query, { headers })
            .then((response) => {
                return response.data.data.firmConsignee.map((item) => {
                    return { value: item?.consignee_name, label: item?.consignee_name }
                })
            })

            .catch((error) => {
            })

    }

    const handleConsigneeSelect = (e) => {
        setConsignee(e?.value);
    }
    const queryOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterConsignee(inputValue));
            }, 1000);
        });

    return (<>

        <AsyncSelect
            className="basic-single w-100 pe-2"
            cacheOptions
            isClearable
            defaultOptions={tripConsignee}
            loadOptions={queryOptions}
            onChange={handleConsigneeSelect}
        />
    </>
    )
}

export default ConsigneeFilter