import '../Driver/AddDriver.css'
import Bank from './Components/Bank';
import Contacts from './Components/Contacts';
import Details from './Components/Details';
import LicenceDetails from './Components/LicenceDetails';
import References from './Components/References';
import { Step, Stepper, StepLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import DashboardPageNotFound from '../DashboardPageNotFound/DashboardPageNotFound';

function AddDriver() {
    const step = useSelector((state) => state?.driverFormReducer?.currentStep);
    const showStep = (key) => {
        switch (key) {
            case 1:
                return <Details />

            case 2:
                return <Contacts />

            case 3:
                return <LicenceDetails />

            case 4:
                return <References />

            case 5:
                return <Bank />

        }
    }

    return (
        <>
            <div
                className='mb-5'
                style={{
                    backgroundColor: "#10233c",
                    color: "white",
                    height: "40px",
                    textAlign: "center"
                }} >
                <h4 className="pt-1">Add New Driver</h4>
            </div>
            <div className='align-items-center'>
                <Stepper style={{ width: '100%', }} activeStep={step - 1} orientation='horizontal' alternativeLabel>
                    <Step>
                        <StepLabel>Personal Details</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Contact Details</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Licence Details</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Reference</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Bank Details</StepLabel>
                    </Step>
                </Stepper>
            </div>

            <div > {showStep(step)}</div>
        </>
    );
}

export default AddDriver;