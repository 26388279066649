import React from "react";
import { useForm } from "react-hook-form";
import "../Firms/AddFirms/AddFirms.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../../config";
import { setReload } from "../../../features/Login";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import { capitalizeInput } from "../../common/FunctionsC/functionsC";
import Loader from "../../common/Spinner/Loader";
import { Modal } from 'react-bootstrap'

const EditFirms = (props) => {
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const user_id = useSelector((state) => state?.loginDetail?.userDetail.id);
    const [firmError, setFirmError] = useState([]);
    const [show, setShow] = useState(false);
    const [messageType, setMessageType] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    const handleFile = (e) => {
        console.log("working")
        setSelectedFile(e.target.files[0]);
    }

    const onSubmit = (data) => {
        const headers = {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "multipart/form-data",
        };

        setLoading(true);
        const formData = new FormData();
        formData.append(
            "operations",
            JSON.stringify({
                query: `
                 mutation(
                      $user_id: ID!,
                      $firm_id: ID!,
                      $firm_name: String!,
                      $firm_email: String!,
                      $firm_contact: String!,
                      $firm_address: String!,
                      $firm_licence: Upload,
                      $firm_cin: String
                      ) {
                editFirm(
                    user_id: $user_id,
                    firm_id: $firm_id,
                    firm_name: $firm_name,
                    firm_email: $firm_email,
                    firm_contact: $firm_contact,
                    firm_address: $firm_address,
                    firm_licence: $firm_licence,
                    firm_cin: $firm_cin
                    ) {
                    error {
                        firm_name
                        firm_email
                        firm_contact
                        firm_address
                    }
                    message
                    type
                }
            }
        `,
                variables: {
                    user_id: user_id,
                    firm_id: parseInt(props?.data?.firm_id),
                    firm_name: data.firm_name,
                    firm_email: data.firm_email,
                    firm_contact: data.firm_contact,
                    firm_address: data.firm_address,
                    firm_cin: data?.firm_cin,
                    firm_licence: selectedFile,
                },
            })
        );
        console.log("inside If block", selectedFile)

        if (selectedFile) {
            console.log("inside If block")
            formData.append("0", selectedFile, selectedFile?.name);
        }
        formData.append(
            "map",
            JSON.stringify({
                0: ["variables.firm_licence"],
            })
        );

        axios
            .post(BASE_URL, formData, { headers })
            .then((response) => {
                console.log("editFirmResponse", response)
                if (response.data?.data.editFirm.type == "success") {
                    setFirmError(response.data?.data.editFirm.message);
                    setMessageType(response.data?.data.editFirm.type);
                    setShow(true);
                }
                if (response.data?.data.editFirm.type == "failed") {
                    setMessageType(response.data?.data.editFirm.type);
                    if (response.data.data.editFirm.error.firm_contact != null)
                        setFirmError(response.data.data.addFirm.error.firm_contact[0]);
                    else if (response.data.data.editFirm.error.firm_email != null)
                        setFirmError(response.data.data.editFirm.error.firm_email[0]);
                    else if (response.data.data.editFirm.error.firm_pancard != null)
                        setFirmError(response.data.data.editFirm.error.firm_pancard[0]);
                }
                setShow(true);

            })
            .catch((error) => { })
            .finally(() => {
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                    props?.closeModalEdit(false)
                    setMessageType("");
                    setFirmError("")
                    setShow(false);
                    setSelectedFile("")
                }, 3000)
            })

    };

    const handleCapture = (e) => {
        const result = e.target.value.replace(/[^0-9]/gi, "");
        setValue("firm_contact", result);
    };

    return (
        <>
            <Modal
                show={props?.editFirmModal}
                scrollable={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props?.closeModalEdit(false)}
            >
                <Modal.Header
                    closeButton
                >
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            backgroundColor: "#10233c",
                            color: "white",
                            height: "40px",
                            textAlign: "center",
                        }}
                    >
                        <h4 className="pt-1">Update Firm</h4>
                    </div>

                    <form
                        className="addtruck-form  mt-5 p-2"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="m-0 p-0">
                            <div className="form-group">
                                <label for="">
                                    Firm Licence{" "}
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    onChangeCapture={(e) => handleFile(e)}
                                    {...register("firm_licence", {
                                    })}
                                />
                                {errors?.firm_licence?.type === "required" && (
                                    <p className="error_p">Firm name is required</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label for="">
                                    Firm Name{" "}
                                    <sup>
                                        <i class="bi bi-asterisk my-icon"></i>
                                    </sup>{" "}
                                </label>
                                <input
                                    type="text"
                                    onInput={(e) => capitalizeInput(e)}
                                    defaultValue={props?.data?.firm_name}
                                    className="form-control"
                                    id="firm_name"
                                    {...register("firm_name", {
                                        required: true,
                                    })}
                                />
                                {errors?.firm_name?.type === "required" && (
                                    <p className="error_p">Firm name is required</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label type="text" for="">
                                    Contact{" "}
                                    <sup>
                                        <i class="bi bi-asterisk my-icon"></i>
                                    </sup>{" "}
                                </label>
                                <input
                                    type="contact"
                                    className="form-control"
                                    defaultValue={props?.data?.firm_contact}
                                    onChangeCapture={handleCapture}
                                    id=""
                                    {...register("firm_contact", {
                                        required: true,
                                        pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                    })}
                                />
                                {errors?.firm_contact?.type === "required" && (
                                    <p className="error_p">Contact number is required</p>
                                )}
                                {errors?.firm_contact?.type === "pattern" && (
                                    <p className="error_p">Please entre a valid Contact number</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label for="">
                                    Email{" "}
                                    <sup>
                                        <i class="bi bi-asterisk my-icon"></i>
                                    </sup>{" "}
                                </label>
                                <input
                                    type="email"
                                    defaultValue={props?.data?.firm_email}
                                    className="form-control"
                                    id=""
                                    {...register("firm_email", {
                                        required: true,
                                    })}
                                />
                                {errors?.firm_email?.type === "required" && (
                                    <p className="error_p">Email is required</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label for="">
                                    Address{" "}
                                    <sup>
                                        <i class="bi bi-asterisk my-icon"></i>
                                    </sup>{" "}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={props?.data?.firm_address}
                                    onInput={(e) => capitalizeInput(e)}
                                    id=""
                                    {...register("firm_address", {
                                        required: true,
                                    })}
                                />
                                {errors?.firm_address?.type === "required" && (
                                    <p className="error_p">Address is required</p>
                                )}
                            </div>


                            <div className="form-group">
                                <label for="">CIN </label>
                                <input
                                    defaultValue={props?.data?.firm_cin}
                                    type="text"
                                    className="form-control"
                                    id=""
                                    {...register("firm_cin", {})}
                                />
                            </div>

                            <button
                                type="submit"
                                className="btn dark-btn mx-auto d-block mt-5 mb-3"
                            >
                                {loading ? (
                                    <>
                                        <Loader /> Submitting...
                                    </>
                                ) : (
                                    <>Submit</>
                                )}
                            </button>

                            {show && (
                                <CustomizedSnackbars
                                    show={show}
                                    message={firmError}
                                    messageType={messageType}
                                />
                            )}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal >
        </>
    );
};
export default EditFirms;
