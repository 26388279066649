import axios from "axios";
import React, { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import TanStackTable from "../../common/Table/TanStackTable";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import { useSelector } from "react-redux";
import Select from "react-select";
import DateFilter from "../Filters/DateFilter";
import InvoiceNumberFilter from "../Filters/InvoiceNumberFilter";
import ConsignerFilter from "../Filters/ConsignerFilter";
import ConsigneeFilter from "../Filters/ConsigneeFilter";
import SourceFilter from "../Filters/SourceFilter";
import DestinationFilter from "../Filters/DestinationFilter";
import { BASE_URL } from "../../../config";
import ClosedTripView from "./ClosedTripView";

const ClosedTrips = ({ activeKey }) => {
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const selectedConsigner = useSelector(
    (state) => state?.filterComponents?.selectedConsigner
  );
  const selectedConsignee = useSelector(
    (state) => state?.filterComponents?.selectedConsignee
  );
  const selectedInvoiceNumber = useSelector(
    (state) => state?.filterComponents?.selectedInvoiceNumber
  );
  const selectedSource = useSelector(
    (state) => state?.filterComponents?.selectedSource
  );
  const selectedDestination = useSelector(
    (state) => state?.filterComponents?.selectedDestination
  );
  const selectedDate = useSelector(
    (state) => state?.filterComponents?.selectedDate
  );
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);
  const [truck, setTruck] = useState();
  const [truckNumber, setTruckNumber] = useState([]);
  const [filterTruckNumber, setFilterTruckNumber] = useState([]);
  const [modalHandler, setModalHandler] = useState(false);
  const [tripId, setTripId] = useState();
  const columnHelper = createColumnHelper();
  const bearerToken = useSelector((state) => state.loginDetail.bearerToken);
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const query = {
      query: `query($firm_id:ID!,
                        $trip_status:String,
                        $truck_number:String,
                        $trip_date:String,
                        $trip_consigner:String,
                        $trip_consignee:String,
                        $trip_source:String,
                        $trip_destination:String,
                        $trip_product:String,
                        $trip_invoice_number:String
                          ){
        firmTrip(firm_id:$firm_id,
                truck_number:$truck_number,
                trip_status:$trip_status,
                trip_date:$trip_date,
                trip_consigner: $trip_consigner,
                trip_consignee:$trip_consignee,
                trip_source:$trip_source,
                trip_destination:$trip_destination,
                trip_product:$trip_product,
                trip_invoice_number:$trip_invoice_number
        ) {
          truckTrip {
            truck_id
            trip_date
            trip_id
            trip_source
            trip_destination
            trip_status
            trip_consigner
            trip_consignee
            trip_product
            trip_invoice_number
            trip_invoice_value 
            truck{
              truck_number
            }
          }
        }
      }`,
      variables: {
        firm_id: firm_id,
        trip_status: "closed",
        truck_number: truck,
        trip_date: selectedDate,
        trip_source: selectedSource,
        trip_destination: selectedDestination,
        trip_consigner: selectedConsigner,
        trip_consignee: selectedConsignee,
        trip_invoice_number: selectedInvoiceNumber,
      },
    };

    axios
      .post(BASE_URL, query, { headers })
      .then((response) => {
        setTruckNumber(
          response.data?.data.firmTrip.truckTrip?.map((trip) => {
            return trip.truck.truck_number;
          })
        );
        setContent((response.data?.data.firmTrip.truckTrip).reverse());
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [
    firm_id,
    truck,
    modalHandler,
    selectedDate,
    selectedSource,
    selectedDestination,
    selectedConsigner,
    selectedConsignee,
    selectedInvoiceNumber,
    activeKey
  ]);

  const columns = [
    columnHelper.accessor((row) => row.truck.truck_number, {
      id: "truck_number",
      cell: (info) => info.getValue(),
      header: () => <span>Truck Number</span>,
    }),
    columnHelper.accessor("trip_date", {
      header: "Date",
      cell: (info) => info.getValue().split("-").reverse().join("-"),
    }),
    columnHelper.accessor("trip_source", {
      header: "Source",
    }),
    columnHelper.accessor("trip_destination", {
      header: "Destination",
    }),
    columnHelper.accessor("trip_consigner", {
      header: "Consigner",
    }),
    columnHelper.accessor("trip_consignee", {
      header: "Consignee",
    }),
    columnHelper.accessor("trip_product", {
      header: "Product",
    }),
    columnHelper.accessor("trip_invoice_number", {
      header: "Invoice Number",
    }),
    columnHelper.accessor("trip_invoice_value", {
      header: "Invoice Value",
      cell: (info) => "₹ " + info.getValue(),
    }),
    columnHelper.accessor("trip_id", {
      header: "view",
      cell: (props) => (
        <button
          type="button"
          value={props.getValue()}
          className="dark-btn rounded m-auto  bi bi-eye"
          onClick={handleMoreDetailsView}
        ></button>
      ),
    }),
  ];

  const handleSelect = (event) => {
    setTruck(event?.value);
  };

  useEffect(() => {
    setFilterTruckNumber(
      truckNumber
        ?.filter(function (item, pos) {
          return truckNumber.indexOf(item) == pos;
        })
        ?.map((truck) => {
          return { value: truck, label: truck };
        })
    );
  }, [truckNumber, content, truck]);

  const handleMoreDetailsView = (e) => {
    setModalHandler(true);
    setTripId(e.target.value);
  };

  const ModelAction = (modalHandler) => {
    setModalHandler(modalHandler);
  };

  return (
    <>
      <div className="d-flex justify-content-evenly filter-overlay">
        <div className="d-flex flex-column w-25 pe-2 mt-3">
          <label>Date</label>
          <DateFilter />
        </div>
        <div className="d-flex flex-column w-25 pe-2 mt-3">
          <label>Truck Number</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            onChange={handleSelect}
            options={filterTruckNumber}
          />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>Invoice Number</label>
          <InvoiceNumberFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Consigner</label>
          <ConsignerFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Consignee</label>
          <ConsigneeFilter />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>Source</label>
          <SourceFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Destination</label>
          <DestinationFilter />
        </div>
      </div>
      <hr />

      {loading ? (
        <SimpleBackdrop />
      ) : content?.length > 0 ? (
        <>
          <TanStackTable data={content} columns={columns} />
        </>
      ) : (
        <div className="d-flex aline-item-center pt-2 no_data-img">
          <img src="/no_data.png"></img>
        </div>
      )}

      {modalHandler && (
        <ClosedTripView
          modalHandler={modalHandler}
          modalAction={ModelAction}
          tripId={tripId}
        />
      )}
    </>
  );
};

export default ClosedTrips;
