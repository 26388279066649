
import Header from "./Components/common/Header/Header";
import Sidebar from "./Components/common/Sidebar/Sidebar";
import Dashboard from "./Components/pages/Dashboard/Dashboard";
import AddTruck from "./Components/pages/Trucks/AddTruck/AddTruck";
import Truck from "./Components/pages/Trucks/Truck";
import Verification from "./Components/pages/Verification/Verification";
import Trips from "./Components/pages/Trips/Trips";
import Expense from "./Components/pages/Expenses/Expense";
import Firms from "./Components/pages/Firms/Firms";
import AddDriver from "./Components/pages/Driver/AddDriver";
import AddFirms from "./Components/pages/Firms/AddFirms/AddFirms";
import Registration from "./Components/pages/Registration/Registration";
import Login from "./Components/pages/Login/Login";
import { useSelector } from "react-redux";
import ForgotPassword from "./Components/pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/pages/ResetPasword/ResetPassword";
import PageNotFound from "./Components/pages/PageNotFound/PageNotFound";
import DashboardPageNotFound from "./Components/pages/DashboardPageNotFound/DashboardPageNotFound";
import ChangePassword from "./Components/pages/ChangePassword/ChangePassword";
import Profile from "./Components/pages/Profile/Profile";
import LoadTruck from "./Components/pages/LoadTruck/LoadTruck";
import AddConsigner from "./Components/pages/Add/AddConsigner";
import AddConsignee from "./Components/pages/Add/AddConsignee";
import Drivers from "./Components/pages/Driver/Drivers";
import Add from "./Components/pages/Add/Add";
import InvoiceData from "./Components/pages/Invoice/InvoiceData";
import AddProduct from "./Components/pages/Add/AddProduct";
import { Route, Routes } from 'react-router-dom';
import BuyPlans from "./Components/pages/BuyPlans/BuyPlans";
import Builty from "./Components/pages/Builty/Builty";
import FAQs from "./Components/pages/FAQs/FAQ";
import AddVendor from "./Components/pages/Uploads/AddVendor";
import UploadDoc from "./Components/pages/Uploads/UploadDoc";
import PrivacyPolicy from "./PrivacyPolicy";

const AllRoutes = () => {
  const login = useSelector((state) => state?.loginDetail?.login);
  return (
    <>
      {
        login
          ?
          <>
            <Header />
            <div className='conatiner-fluid'>
              <div className='row m-0'>
                <div className='col-md-3 col-xl-2 ps-md-0 navbar navbar-expand-md'>
                  <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                    <span><i className="bi bi-list"></i></span>
                  </button>
                  <Sidebar />
                </div>
                <div className='col-md-9 col-xl-10'>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/firms" element={<Firms />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/addfirms" element={<AddFirms />} />
                    <Route path="/trucks" element={<Truck />} />
                    <Route path="/loadtruck" element={<LoadTruck />} />
                    <Route path="/drivers" element={<Drivers />} />
                    <Route path="/trips" element={<Trips />} />
                    <Route path="/expense" element={<Expense />} />
                    <Route path="/invoice" element={<InvoiceData />} />
                    <Route path="/addtruck" element={<AddTruck />} />
                    <Route path="/adddriver" element={<AddDriver />}></Route>
                    <Route path="/addconsigner" element={<AddConsigner />} />
                    <Route path="/addconsignee" element={<AddConsignee />} />
                    <Route path="addproduct" element={<AddProduct />} />
                    <Route path="/add" element={<Add />} />
                    <Route path="/buyplans" element={<BuyPlans />} />
                    <Route path="/buyplans" element={<BuyPlans />} />
                    <Route path="/builty" element={<Builty />} />
                    <Route path="/uploads" element={<UploadDoc />} />
                    <Route path="/addvendor" element={<AddVendor />} />
                    <Route path="/faqs" element={<FAQs />} />
                    <Route path="*" element={<DashboardPageNotFound />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  </Routes>
                </div>
              </div>
            </div>

          </>
          :
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/verify" element={<Verification />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token/:email" element={<ResetPassword />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
      }
    </>
  )
}

export default AllRoutes;