import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { setSelectedLrNumber } from '../../../features/FilterComponents';
import { BASE_URL } from '../../../config';

const LrNumberFilter = () => {
    const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [tripLrNumber, setTripLrNumber] = useState();
    const [lrNumber, setLrNumber] = useState();
    const dispatch = useDispatch();
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        dispatch(setSelectedLrNumber(lrNumber))
    }, [lrNumber])

    useEffect(() => {
        // ==== Query to get Invoice Number and LR Number ========
        const query = {
            query: `query($firm_id:ID!,$limit:Int){
                firmInvoice(firm_id:$firm_id,limit:$limit) {
                 lr_number
                }
             }`,
            variables: {
                firm_id: firm_id,
                limit: 3
            }
        }
        axios.post(BASE_URL, query, { headers })
            .then(response => {
                setTripLrNumber(response.data?.data.firmInvoice?.map((item) => {
                    return { value: item.lr_number, label: item.lr_number }
                }))
            })
            .catch(error => {
            })
    }, [firm_id])

    const filterLrNumber = (inputValue) => {
        const query = {
            query: `query($firm_id:ID,$inputLr:String){
            filterInvoiceNumber(firm_id:$firm_id,inputLr:$inputLr) 
            {
              lr_number
            }
          }`,
            variables: {
                firm_id: firm_id,
                inputLr: inputValue
            }
        }

        return axios.post(BASE_URL, query, { headers })
            .then((response) => {
                return response.data.data?.filterInvoiceNumber?.map((item) => {
                    return { value: item?.lr_number, label: item?.lr_number }
                })
            })
            .catch((error) => {

            })

    }
    const queryOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterLrNumber(inputValue));
            }, 1000);
        });

    const handleLrNumberSelect = (e) => {
        setLrNumber(e?.value);
    }


    return (<>
        <AsyncSelect
            className="basic-single w-100 pe-2"
            cacheOptions
            isClearable
            defaultOptions={tripLrNumber}
            loadOptions={queryOptions}
            onChange={handleLrNumberSelect}
        />

    </>)

}
export default LrNumberFilter