//  local url -------------> 
// export const BASE_URL = 'http://127.0.0.1:8000/graphql';
// export const BACKEND_URL = "http://127.0.0.1:8000";


// Test Url --------------->
// export const BASE_URL = 'https://bill.smashinginfolabs.com/backend/public/graphql';
// export const BACKEND_URL = "https://bill.smashinginfolabs.com/backend/public;


// Main Url -------------->
// export const BASE_URL = 'https://bill.smashinginfolabs.com/backend/public/graphql';
// export const BACKEND_URL = "https://bill.smashinginfolabs.com/backend/public;



const BASE_URL = process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/graphql'
    : 'https://bill.smashinginfolabs.com/backend/public/graphql';


const BACKEND_URL = process.env.NODE_ENV === "development"
    ?
    'http://127.0.0.1:8000/'
    :
    'https://bill.smashinginfolabs.com/backend/public/';

export {
    BASE_URL,
    BACKEND_URL
};



