import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import AddConsignee from './AddConsignee';
import AddConsigner from './AddConsigner';
import AddProduct from './AddProduct';

function Add() {
  return (<>
    <div className='tabs-sec'>
      <Tabs
        defaultActiveKey="addconsigner"
        id="uncontrolled-tab-example"
        className="mb-3"
        justify

      >
        <Tab eventKey="addconsigner" title="ADD CONSIGNER">
          <AddConsigner />
        </Tab>
        <Tab eventKey="addconsignee" title="ADD CONSIGNEE" >
          <AddConsignee />
        </Tab>
        <Tab eventKey="addproduct" title="ADD PRODUCT" >
          <AddProduct />
        </Tab>

      </Tabs>
    </div>

  </>)
}

export default Add;