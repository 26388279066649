import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { date } from 'yup';
import { setSelectedDate } from '../../../features/FilterComponents';

const DateFilter = () => {
    const [date, setDate] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedDate(date));
    }, [date])

    const handleSelectedDate = (e) => {
        setDate(e?.target.value)
    }

    return <>
        <Form.Control type="date" name="invoice_date" onChangeCapture={handleSelectedDate} />
    </>
}

export default DateFilter