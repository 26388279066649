import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setDecrem, setSubmit } from "../../../../features/TruckForm";
import { BASE_URL } from "../../../../config";
import axios from "axios";
import CustomizedSnackbars from "../../../common/Popup/Snackbar";
import Loader from "../../../common/Spinner/Loader";
import DocModal from "../../../common/DocModal/DocModal";
import { TbCameraPlus } from "react-icons/tb";
const TruckDocuments = ({ truck_id }) => {
  const dispatch = useDispatch();
  const TruckDetails = useSelector(
    (state) => state?.truckFormReducer?.addTruckDetails
  );
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [show, setShow] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [truckMessage, setTruckMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const [ResDoc, setResDoc] = useState(false)
  const [pucDoc, setPucDoc] = useState(false)
  const [permitDoc, setPermitDoc] = useState(false)
  const [insuranceDoc, setInsuranceDoc] = useState(false)
  const [fitnessDoc, setFitnessDoc] = useState(false)
  const [docModalShow, setDocModalShow] = useState(false)
  const [submitType, setSubmitType] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const handleBackStep = (event) => {
    event.preventDefault();
    dispatch(setDecrem());
  };

  const onSubmit = (data) => {

    console.log("data", data)
    console.log("docType", submitType)
    console.log("firm_id", firm_id)
    console.log("truck_id", truck_id)

    setLoading(true)
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "multipart/form-data",
    };

    const doc_file = data.doc_file[0];

    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `mutation(       
                          $firm_id:ID!,
                          $truck_id:ID!,
                          $doc_type:String!,
                          $doc_file:Upload!,
                          $doc_number:String!,
                          $doc_issue_date:Date!,
                          $doc_expiry_date:Date!,
                       ){
                          addTruckDocument( 
                              firm_id:$firm_id,
                              truck_id:$truck_id,
                              doc_type:$doc_type,
                              doc_file:$doc_file,
                              doc_number:$doc_number,
                              doc_issue_date:$doc_issue_date,
                              doc_expiry_date:$doc_expiry_date,
                            )
                           {
                            type
                            message
                            error{
                              firm_id
                              truck_id
                              doc_type
                              doc_file
                              doc_number
                              doc_issue_date
                              doc_expiry_date
                                  }
                           }
                       }`,
        variables: {
          firm_id: firm_id,
          truck_id: truck_id,
          doc_type: submitType,
          doc_file: doc_file,
          doc_number: data.doc_number,
          doc_issue_date: data.doc_issue_date,
          doc_expiry_date: data.doc_expiry_date,
        }
      }))

    formData.append("0", doc_file, doc_file.name);
    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.doc_file"],
      })
    );

    axios
      .post(BASE_URL, formData, { headers })
      .then((response) => {
        console.log("add truck document response", response)
        setLoading(false)
        setMessageType(response.data?.data.addTruckDocument.type);
        setShow(true);
        if (response.data?.data.addTruckDocument.type == "success") {
          setTruckMessage(response.data?.data.addTruckDocument.message);
          setTimeout(() => {
            reset()
          }, 3000);
        } else {
          setTruckMessage(response.data?.data.addTruckDocument.message);
        }
        // if (response.data?.data.addTruck.type == "failed") {
        //   if (response.data.data.addTruck.error.truck_number != null) {
        //     setTruckError(response.data?.data.addTruck.error.truck_number[0]);
        //   }
        // }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
        dispatch(setSubmit());
        setMessageType();
      }, 4000);
    }
  }, [show]);

  const handleRegistrationDoc = (e, type) => {
    reset()
    setResDoc(type)
    setSubmitType("registration_card")
    setPucDoc(false)
    setInsuranceDoc(false)
    setFitnessDoc(false)
    setPermitDoc(false)
  }

  const handlePucDoc = (e, type) => {
    setPucDoc(type)
    setSubmitType("puc_certificate")
    setResDoc(false)
    setInsuranceDoc(false)
    setFitnessDoc(false)
    setPermitDoc(false)
    reset()
  }

  const handleInsuranceDoc = (e, type) => {
    setInsuranceDoc(type)
    setSubmitType("insurance")
    setResDoc(false)
    setPucDoc(false)
    setFitnessDoc(false)
    setPermitDoc(false)
  }

  const handlePermitDoc = (e, type) => {
    setPermitDoc(type)
    setSubmitType("permit")
    setResDoc(false)
    setPucDoc(false)
    setInsuranceDoc(false)
    setFitnessDoc(false)
  }

  const handleFitnessDoc = (e, type) => {
    setFitnessDoc(type)
    setSubmitType("fitness")
    setResDoc(false)
    setPucDoc(false)
    setInsuranceDoc(false)
    setPermitDoc(false)
  }

  const DocModalHandler = (type) => {
    setDocModalShow(true)
    setSubmitType(type)
  }
  const closeModal = () => {
    setDocModalShow(false)
  }
  return (
    <>
      {docModalShow && <DocModal
        docType={submitType}
        show={docModalShow}
        closeModal={closeModal}
        url="./PP.png"
        firm_id={firm_id}
        truck_id={truck_id}
      />}

      <form className="addtruck-form mt-3" onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <div className="d-flex ">
            {!ResDoc &&
              <>
                <div className="add_img add_doc_heading" onClick={(e) => handleRegistrationDoc(e, true)}>
                  <TbCameraPlus style={{ fontSize: "30px" }} />
                </div>

                <div className="ms-3">
                  <p className="add_doc_heading" onClick={(e) => handleRegistrationDoc(e, true)}>Registration</p>
                  <p className="add_doc_heading doc_view " onClick={() => DocModalHandler("registration_card")} >View</p>
                </div>
              </>
            }
            {ResDoc && <div className="d-flex justify-content-between w-100"> <p>Registration</p> <p onClick={(e) => handleRegistrationDoc(e, false)}><i class="bi bi-x-square " style={{ color: "red" }}></i></p></div>}
          </div>
          {ResDoc && <li>
            <div className="form-group">
              <label for="doc_file">Registration Card <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="file"
                {...register("doc_file", {
                  required: true
                })}
              />
              {errors?.doc_file?.type === "required" && (
                <p className="error_p">Kindly Upload Registration Card </p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_number">RC Number <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="text"
                {...register("doc_number", {
                  required: true
                })}
              />
              {errors?.doc_number?.type === "required" && (
                <p className="error_p">RC Number is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_issue_date">Issue Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_issue_date", {
                  required: true
                })}
              />
              {errors?.doc_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_expiry_date">Expiry Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_expiry_date", {
                  required: true
                })}
              />
              {errors?.doc_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both", display: "flex", justifyContent: "flex-end", paddingBottom: "5px" }} >
              <button type="submit" className="dark-btn rounded ">Submit</button>
            </div>
          </li>
          }
        </ul>
      </form>
      {/* ================================ P U C ============================================= */}
      <form className="addtruck-form mt-3 " onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {/* <div className="d-flex justify-content-between"> <p onClick={(e) => handlePucDoc(e, true)}>PUC Doc</p> {pucDoc && <p onClick={(e) => handlePucDoc(e, false)}><i class="bi bi-x-square " style={{color:"red",fontSize:"20px}}></i></p>}</div> */}
          <div className="d-flex ">
            {!pucDoc &&
              <>
                <div className="add_img add_doc_heading" onClick={(e) => handlePucDoc(e, true)}>
                  <TbCameraPlus style={{ fontSize: "30px" }} />
                </div>

                <div className="ms-3">
                  <p className="add_doc_heading" onClick={(e) => handlePucDoc(e, true)}>PUC </p>
                  <p onClick={() => DocModalHandler("puc_certificate")} className="doc_view add_doc_heading">View</p>
                </div>
              </>
            }
            {pucDoc && <div className="d-flex justify-content-between w-100"> <p >PUC</p> <p onClick={(e) => handlePucDoc(e, false)}><i class="bi bi-x-square " style={{ color: "red" }}></i></p></div>}
          </div>
          {pucDoc && <li>
            <div className="form-group">
              <label for="doc_file">PUC Certificate <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="file"
                {...register("doc_file", {
                  required: true
                })}
              />
              {errors?.doc_file?.type === "required" && (
                <p className="error_p">Kindly Upload PUC Certificate</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_number">PUC Number <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="text"
                {...register("doc_number", {
                  required: true
                })}
              />
              {errors?.doc_number?.type === "required" && (
                <p className="error_p">PUC Number is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_issue_date">Issue Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_issue_date", {
                  required: true
                })}
              />
              {errors?.doc_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_expiry_date">Expiry Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_expiry_date", {
                  required: true
                })}
              />
              {errors?.doc_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both", display: "flex", justifyContent: "flex-end" }}>
              <button type="submit" className="dark-btn rounded">Submit</button>
            </div>
          </li>
          }
        </ul>
      </form>
      {/* ================= INSURANCE ============================================ */}
      <form className="addtruck-form mt-3" onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {/* <div className="d-flex justify-content-between"> <p onClick={(e) => handleInsuranceDoc(e, true)}>Insurance</p> {insuranceDoc && <p onClick={(e) => handleInsuranceDoc(e, false)}><i class="bi bi-x-square " style={{color:"red",fontSize:"20px}}></i></p>}</div> */}

          <div className="d-flex ">
            {!insuranceDoc &&
              <>
                <div className="add_img add_doc_heading" onClick={(e) => handleInsuranceDoc(e, true)}>
                  <TbCameraPlus style={{ fontSize: "30px" }} />
                </div>

                <div className="ms-3">
                  <p className="add_doc_heading" onClick={(e) => handleInsuranceDoc(e, true)}>Insurance</p>
                  <p onClick={() => DocModalHandler("insurance")} className="doc_view add_doc_heading">View</p>
                </div>
              </>
            }
            {insuranceDoc && <div className="d-flex justify-content-between w-100"> <p>Insurance</p> <p onClick={(e) => handleInsuranceDoc(e, false)}><i class="bi bi-x-square " style={{ color: "red" }}></i></p></div>}
          </div>

          {insuranceDoc && <li>
            <div className="form-group">
              <label for="doc_file">Insurance Policy <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="file"
                {...register("doc_file", {
                  required: true
                })}
              />
              {errors?.doc_file?.type === "required" && (
                <p className="error_p">Kindly Upload Insurance Policy</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_number">Policy Number <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="text"
                {...register("doc_number", {
                  required: true
                })}
              />
              {errors?.doc_number?.type === "required" && (
                <p className="error_p">Policy Number is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_issue_date">Issue Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_issue_date", {
                  required: true
                })}
              />
              {errors?.doc_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_expiry_date">Expiry Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_expiry_date", {
                  required: true
                })}
              />
              {errors?.doc_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both", display: "flex", justifyContent: "flex-end" }}>
              <button type="submit" className="dark-btn rounded">Submit</button>
            </div>
          </li>
          }
        </ul >
      </form>
      {/* =============================== Permit =============================================== */}
      <form className="addtruck-form mt-3" onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <div className="d-flex ">
            {!permitDoc &&
              <>
                <div className="add_img add_doc_heading" onClick={(e) => handlePermitDoc(e, true)}>
                  <TbCameraPlus style={{ fontSize: "30px" }} />
                </div>

                <div className="ms-3">
                  <p className="add_doc_heading" onClick={(e) => handlePermitDoc(e, true)}>Permit Certificate </p>
                  <p onClick={() => DocModalHandler("permit")} className="doc_view add_doc_heading">View</p>
                </div>
              </>
            }
            {permitDoc && <div className="d-flex justify-content-between w-100"> <p >Permit</p> <p onClick={(e) => handlePermitDoc(e, false)}><i class="bi bi-x-square " style={{ color: "red" }}></i></p></div>}
          </div>
          {permitDoc &&
            <li>
              <div className="form-group">
                <label for="doc_file">Permit Certificate <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
                <input
                  className="form-control"
                  type="file"
                  {...register("doc_file", {
                    required: true
                  })}
                />
                {errors?.doc_file?.type === "required" && (
                  <p className="error_p">Kindly Upload Permit Certificate</p>
                )}
              </div>

              <div className="form-group">
                <label for="doc_number">Permit Number <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
                <input
                  className="form-control"
                  type="text"
                  {...register("doc_number", {
                    required: true
                  })}
                />
                {errors?.doc_number?.type === "required" && (
                  <p className="error_p">Permit Number is required</p>
                )}
              </div>

              <div className="form-group">
                <label for="doc_issue_date">Issue Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
                <input
                  className="form-control"
                  type="date"
                  {...register("doc_issue_date", {
                    required: true
                  })}
                />
                {errors?.doc_issue_date?.type === "required" && (
                  <p className="error_p">Issue Date is required</p>
                )}
              </div>

              <div className="form-group">
                <label for="doc_expiry_date">Expiry Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
                <input
                  className="form-control"
                  type="date"
                  {...register("doc_expiry_date", {
                    required: true
                  })}
                />
                {errors?.doc_expiry_date?.type === "required" && (
                  <p className="error_p">Expiry Date is required</p>
                )}
              </div>
              <div style={{ clear: "both", display: "flex", justifyContent: "flex-end" }}>
                <button type="submit" className="dark-btn rounded">Submit</button>
              </div>
            </li>
          }
        </ul >
      </form >
      {/* =============================  Fitness ===================== */}
      <form className="addtruck-form mt-3" onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {/* <div className="d-flex justify-content-between"> <p onClick={(e) => handlePucDoc(e, true)}>PUC Doc</p> {pucDoc && <p onClick={(e) => handlePucDoc(e, false)}><i class="bi bi-x-square " style={{color:"red",fontSize:"20px}}></i></p>}</div> */}
          <div className="d-flex ">
            {!fitnessDoc &&
              <>
                <div className="add_img add_doc_heading" onClick={(e) => handleFitnessDoc(e, true)}>
                  <TbCameraPlus style={{ fontSize: "30px" }} />
                </div>

                <div className="ms-3">
                  <p className="add_doc_heading" onClick={(e) => handleFitnessDoc(e, true)}>Fitness Certificate </p>
                  <p onClick={() => DocModalHandler("fitness")} className="doc_view add_doc_heading">View</p>
                </div>
              </>
            }
            {fitnessDoc && <div className="d-flex justify-content-between w-100"> <p>Fitness Certificate</p> <p onClick={(e) => handleFitnessDoc(e, false)}><i class="bi bi-x-square " style={{ color: "red" }}></i></p></div>}
          </div>
          {fitnessDoc && <li>
            <div className="form-group">
              <label for="doc_file">Fitness Certificate <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="file"
                {...register("doc_file", {
                  required: true
                })}
              />
              {errors?.doc_file?.type === "required" && (
                <p className="error_p">Kindly Upload Fitness Certificate</p>
              )}
            </div>
            <div className="form-group">
              <label for="doc_number">Fitness Number <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="text"
                {...register("doc_number", {
                  required: true
                })}
              />
              {errors?.truck_fitness_number?.type === "required" && (
                <p className="error_p">Fitness Number is required</p>
              )}
            </div>

            <div className="form-group">
              <label for="doc_issue_date">Issue Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_issue_date", {
                  required: true
                })}
              />
              {errors?.doc_issue_date?.type === "required" && (
                <p className="error_p">Issue Date is required</p>
              )}
            </div>

            <div className="form-group">
              <label for="doc_expiry_date">Expiry Date <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
              <input
                className="form-control"
                type="date"
                {...register("doc_expiry_date", {
                  required: true
                })}
              />
              {errors?.doc_expiry_date?.type === "required" && (
                <p className="error_p">Expiry Date is required</p>
              )}
            </div>
            <div style={{ clear: "both", display: "flex", justifyContent: "flex-end" }}>
              <button type="submit" className="dark-btn rounded">Submit</button>
            </div>
          </li>
          }
        </ul>
      </form>


      {/* <div style={{ clear: "both" }}></div> */}
      <div>
        {/* <button type="submit" className="btn dark-btn mx-auto d-block mt-3">
          {loading ? <><Loader /> {" "}
            Submitting...</> : <>Submit</>}
        </button> */}

        {show && (
          <CustomizedSnackbars
            show={show}
            message={truckMessage}
            messageType={messageType}
          />
        )}
      </div>

    </>
  );
};

export default TruckDocuments;
