import React from 'react'
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setIncrementStep } from '../../../../features/DriverForm';
import moment from 'moment/moment';
import { capitalizeInput } from '../../../common/FunctionsC/functionsC';

function Details() {
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.driverFormReducer?.details);
  const TodayDate = moment().format("YYYY-MM-DD");
  const maxDOB = moment().subtract(18, 'years').format("YYYY-MM-DD");
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    dispatch(setIncrementStep(data));
  }
  const handleCapture = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("driver_aadhaar", result);
  };


  return (<>
    <div className='mt-5 '>
      <Form className='adddriver-form box-shadow p-3' onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Type  <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Select defaultValue={details.driver_type_} aria-label="Default select example"
            {...register("driver_type_", {
              required: true,
            })}>
            <option value="">- Select -</option>
            <option value="driver">Driver</option>
            <option value="second_driver">2nd Driver</option>
            <option value="cleaner">Cleaner</option>
            <option value="helper">Helper</option>
          </Form.Select>
          {errors?.driver_type_?.type === "required" && <p className="error_p">Type field is required</p>}
        </Form.Group>

        <Form.Group className="form-group" controlId="">
          <Form.Label>Name <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={details.driver_name_}
            onInput={(e) => capitalizeInput(e)}
            type="text"
            {...register("driver_name_", {
              required: true,

            })} />
          {errors?.driver_name_?.type === "required" && <p className="error_p"> Driver Name is required</p>}
        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Father's Name <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control
            onInput={(e) => capitalizeInput(e)} defaultValue={details.driver_father_name} type="text"
            {...register("driver_father_name", {
              required: true,
            })} />
          {errors?.driver_father_name?.type === "required" && <p className="error_p">Driver's father name is required</p>}
        </Form.Group>

        <Form.Group className="form-group" controlId="">
          <Form.Label>Date of Birth <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={details.driver_dob} max={maxDOB} type="date"
            {...register("driver_dob", {
              required: true,
              max: maxDOB
            })} />
          {errors?.driver_dob?.type === "required" && <p className="error_p">Date of Birth is required</p>}
          {errors?.driver_dob?.type === "max" && <p className="error_p">Must be 18 Years old</p>}
        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Date of Joining <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={details.driver_join_date} max={TodayDate} type="date"
            {...register("driver_join_date", {
              required: true,
            })} />
          {errors?.driver_join_date?.type === "required" && <p className="error_p">Joining dateis required</p>}
        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Aadhaar Number <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={details.driver_aadhaar}
            onChangeCapture={handleCapture}
            type="text" placeholder='xxxx xxxx xxxx'
            {...register("driver_aadhaar", {
              required: true,
              pattern: /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/
            })} />
          {errors?.driver_aadhaar?.type === "required" && <p className="error_p">Aadhaar Card Number required</p>}
          {errors?.driver_aadhaar?.type === "pattern" && <p className="error_p">Not a Valid Aadhaar Number</p>}

        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label>Aadhar Card <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control type='file'
            {...register("driver_uploaded_aadhar", {
              required: true,
            })}
          />
          {errors?.driver_uploaded_aadhar?.type === "required" && <p className="error_p">Aadhar Card Photo is required</p>}

        </Form.Group>

        <div style={{ clear: "both" }}></div>
        <button type="submit" className='btn-next mb-0 p-0' >
          <h1><i className="bi bi-arrow-right-square-fill" ></i></h1>
        </button>
        <div style={{ clear: "both" }}></div>
      </Form>
    </div>
  </>
  )
}

export default Details;