import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    step: 1,
    addTruckStep: 1,
    truckDetails: {},
    invoiceDetails: {},
    addTruckDetails: {},
};

export const truckFormReducer = createSlice({
    name: "truckFormReducer",
    initialState,
    reducers: {
        setIncrem: (state, action) => {
            if (action.payload.truck_id) {
                state.truckDetails = action.payload;
                state.step += 1;
            }
            if (action.payload.engine_number) {
                state.addTruckDetails = action.payload;
                state.addTruckStep += 1;
            }

        },
        setDecrem: (state) => {
            if (state.step === 2) {
                state.step -= 1;
            }
            if (state.addTruckStep === 2) {
                state.addTruckStep -= 1;
            }
        },
        setSubmit: (state) => {
            state.step = 1;
            state.addTruckStep = 1;
            state.truckDetails = {};
            state.addTruckDetails = {};
        }
    }
});

export const { setIncrem, setDecrem, setSubmit } = truckFormReducer.actions;

export default truckFormReducer.reducer;