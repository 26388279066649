import React from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import TanStackTable from '../../common/Table/TanStackTable';
import { createColumnHelper } from '@tanstack/react-table';
import { BASE_URL } from '../../../config';
import { setLogout } from '../../../features/Login';
import TruckModal from './TruckModal';
import DeleteTruck from './DeleteTruck';
import AddTruckModal from './AddTruck/AddTruckModal';
import Loader from "react-js-loader";

function Truck() {
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const logDet = useSelector((state) => state);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [truckId, setTruckId] = useState();
  const [truckData, setTruckData] = useState([]);
  const [loading, setLoading] = useState(false);
  const columnHelper = createColumnHelper();
  const [modalHandler, setModalHandler] = useState(false)
  const [truckStatus, setTruckStatus] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [truckForDelete, setTruckForDelete] = useState()
  const [addNewTruckModal, setAddNewTruckModal] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("FID", firm_id)
  console.log("logDet", logDet)

  const truckOptions = [{ value: "Idle", label: "Idle" }, { value: "Active", label: "Active" }, { value: "Maintenance", label: "Maintenance" }]

  useEffect(() => {
    setLoading(true);
    const query = {
      query: `query($firm_id:ID!,$isTruckDeleted:Boolean!){
                truck(firm_id:$firm_id,isTruckDeleted:$isTruckDeleted){
                  truck_number
                  truck_id
                  model
                  old_registration
                  new_registration
                  manufacturing_year
                  valid_upto
                  ownership
                  registration_date
                  fastag_number
                  rto
                  engine_number
                  chasis_number
                  truck_length
                  trolley_length
                  gross_weight
                  unloading_weight
                  payloading_weight
                  purchased_date
                  invoice
                  total_cost
                  model_type
                  body_type
                  truck_status
                  
                }
              }`,
      variables: {
        firm_id: firm_id,
        isTruckDeleted: false
      },
    };

    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    }

    axios.post(BASE_URL, query, { headers })
      .then(response => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout())
        }
        else {
          setTruckData(response.data?.data.truck)
          setTruckData(truckData => [...truckData].reverse());
          setLoading(false);
        }
      }).catch(error => {
        setLoading(false);
      })

  }, [firm_id, showDeleteModal, addNewTruckModal, modalHandler])

  const columns = [

    columnHelper.accessor('truck_number', {
      header: 'Truck No.'
    }),
    columnHelper.accessor('truck_status', {
      header: 'Truck Status',
    }),
    columnHelper.accessor('ownership', {
      header: 'Ownership',
    }),
    columnHelper.accessor('body_type', {
      header: 'Body Type',
    }),
    columnHelper.accessor('truck_id', {
      header: 'More Details',
      cell: (props) => <button value={props.getValue()} className='dark-btn rounded m-auto bi bi-eye' onClick={handleTruckModalShow}></button>
    }),
    columnHelper.accessor('truck_id', {
      header: 'Delete',
      cell: (props) => (
        <div className='d-flex'>
          <button
            type="button"
            value={props?.row?._valuesCache?.truck_id}
            className="light-btn rounded m-auto bi bi-trash3-fill"
            onClick={handleDeleteTruck}
          ></button>
        </div>
      ),
    }),

  ];

  const handleTruckModalShow = (event) => {
    setModalHandler(true)
    setTruckId(event.target.value)

  }

  const ModelAction = (modalHandler) => {
    setModalHandler(modalHandler)
  }
  const handleClose = (type) => setShowDeleteModal(type);
  const handleDeleteTruck = (e) => {
    setShowDeleteModal(true)
    setTruckForDelete(e.target.value)
  }
  useEffect(() => { }, [modalHandler]);
  const handleShowAddTruck = () => {
    setAddNewTruckModal(true)
  }
  const handleCloseAddTruck = (type) => setAddNewTruckModal(type);
  return (
    <>
      <div className='mb-4'
        style={{
          backgroundColor: "#10233c",
          color: "white",
          height: "40px",
          textAlign: "center",
        }}
      >
        <h4 className="pt-1">Truck Details</h4>
      </div>
      <button onClick={handleShowAddTruck} disabled={firm_id ? false : true} className='btn dark-btn'>
        Add Truck
      </button>
      <AddTruckModal show={addNewTruckModal} handleCloseAddTruck={handleCloseAddTruck} firmId={firm_id} />

      {!firm_id && <><p className='error_p mt-2'>Kindly, Register your firm...</p><br /></>}
      <hr />
      {
        loading && !truckData?.length
          ?
          <Loader type="spinner-default" bgColor="#f4801f" color="#f4801f" size={80} />
          // <SimpleBackdrop />
          : (truckData?.length > 0
            ? <>
              <TanStackTable data={truckData} columns={columns} />
            </>
            :
            <div className="d-flex aline-item-center pt-2 no_data-img">
              <img src="/no_data.png"></img>
            </div>
          )
      }
      <DeleteTruck show={showDeleteModal} handleClose={handleClose} truckForDelete={truckForDelete} firmId={firm_id} />
      {
        modalHandler && <TruckModal data={truckData} truckId={truckId} modalAction={ModelAction} modalHandler={modalHandler} />
      }

    </>
  )
}
export default Truck;
