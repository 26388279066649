
import React from 'react';

export default function SimpleBackdrop() {
  return (
    <div className='loading-img'>
      <img src="https://media.tenor.com/WYKoRh1NGPEAAAAd/truck-delivery.gif" alt="" />
    </div>
  );
}
