import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../config';
import { createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import TanStackTable from '../../common/Table/TanStackTable';
import DocModal from '../../common/DocModal/DocModal';
import SimpleBackdrop from '../../common/Spinner/Spinner';

const ViewDocuments = ({ activeKey }) => {
    const firmId = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const columnHelper = createColumnHelper();
    const [docData, setDocData] = useState();
    const [url, setUrl] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    console.log("active", activeKey)


    const columns = [
        columnHelper.accessor('doc_upload_date', {
            header: 'Date',
            cell: info => info.getValue().split("-").reverse().join("-")
        }),
        columnHelper.accessor('doc_vendor', {
            header: 'Vendor'
        }),
        columnHelper.accessor('doc_type', {
            header: 'Type'
        }),
        columnHelper.accessor('doc_payment_type', {
            header: 'Payment Type'
        }),
        columnHelper.accessor('doc_uploader', {
            header: 'Uploader'
        }),
        columnHelper.accessor('doc_id', {
            header: 'File',
            cell: (props) => <button type="button" value={props.getValue()} className="dark-btn m-auto bi bi-eye rounded" onClick={handleDownload}></button>
        }),
    ]

    useEffect(() => {

        const headers = {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
        };

        const getDocuments = {
            query: `query($firm_id:ID!,$isDocDeleted:Boolean!){
                document(firm_id:$firm_id,isDocDeleted:$isDocDeleted){
                    doc_id
                    user_id
                    firm_id
                    doc_upload_date
                    doc_type
                    doc_vendor
                    doc_payment_type
                    doc_uploader
                    doc_file
                }
            }`,
            variables: {
                firm_id: firmId,
                isDocDeleted: false
            }
        }

        axios.post(BASE_URL, getDocuments, { headers })
            .then((response) => {
                setDocData((response?.data?.data?.document).reverse())
                setLoading(false)
            })
            .catch((error) => {
            })
    }, [activeKey, firmId])

    const closeModal = (type) => {
        setIsModalOpen(type);
    };

    const handleDownload = (event) => {

        const docFileQuery = {
            query: `query($firm_id:ID!,$doc_id:ID!){
        docFile(firm_id:$firm_id,doc_id:$doc_id)
        {
            type
            url
            message
        }
      }`,
            variables: {
                firm_id: firmId,
                doc_id: event?.target?.value
            }
        }

        axios.post(BASE_URL, docFileQuery)
            .then((response) => {
                console.log("url", response.data?.data.docFile)
                setUrl(response.data?.data.docFile?.url)
                setIsModalOpen(true)
            })
            .catch((error) => {
            })
    }

    return (<>
        {loading
            ?
            <SimpleBackdrop />
            :
            (docData?.length > 0
                ?
                < TanStackTable data={docData} columns={columns} />
                :
                <div className="d-flex aline-item-center pt-2 no_data-img">
                    <img src="/no_data.png"></img>
                </div>
            )
        }
        <DocModal show={isModalOpen} url={url} closeModal={closeModal} />

    </>
    )
}

export default ViewDocuments