import React from "react";
import InvoiceDetail from "./InvoiceDetail";
import TripDetail from "./TripDetail";
import { Step, Stepper, StepLabel } from '@mui/material';
import { useSelector } from "react-redux";

function LoadTruck() {
    const step = useSelector((state) => state?.truckFormReducer?.step);

    const showStep = (key) => {
        switch (key) {
            case 1:
                return <TripDetail />
            case 2:
                return <InvoiceDetail />
        }
    }

    return (<>
        <div className='mb-4'
            style={{
                backgroundColor: "#10233c",
                color: "white",
                height: "40px",
                textAlign: "center",
            }}
        >
            <h4 className="pt-1">Start Truck Trip</h4>

        </div>
        <div className='align-items-center'>
            <Stepper style={{ width: '80%', marginLeft: "10%", marginTop: "3%" }} activeStep={step - 1} orientation='horizontal' alternativeLabel>
                <Step>
                    <StepLabel>Trip Details</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Invoice Details</StepLabel>
                </Step>
            </Stepper>
            {showStep(step)}
        </div>


    </>)
}

export default LoadTruck;



