import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../../config';
import axios from 'axios';
import CustomizedSnackbars from '../../../common/Popup/Snackbar';

const AddTruckModal = ({ show, handleCloseAddTruck, firmId }) => {
    const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [selectedTruckType, setSelectedTruckType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [messageType, setMessageType] = useState("");
    const [truckError, setTruckError] = useState([]);
    const { register, handleSubmit, watch, reset, setValue, formState: { errors }, } = useForm({ mode: "all", });
    const headers = {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
    };

    useEffect(() => {
        if (show) {
            setTimeout(function () {
                setShowModal(false);
                setMessageType();
            }, 4000);
        }
    }, [show]);

    const onSubmit = (data) => {
        console.log(data)
        console.log("firm_id", firm_id)

        const addTruckMutation = {
            query: `mutation(
              $firm_id:ID!,
              $truck_number:String!,
              $ownership:String!,
              $body_type:String!,
                             ){
                addTruck(
                    firm_id:$firm_id,
                    truck_number:$truck_number,
                    body_type:$body_type,
                    ownership:$ownership,
                    )
                    {
                        message
                        type
                        error{
                            truck_number
                            ownership
                            body_type
                            }
                    }
                    }`,
            variables: {
                firm_id: firm_id,
                truck_number: data.truck_number,
                ownership: data.truck_ownership,
                body_type: data.body_type,
            },
        };

        axios.post(BASE_URL, addTruckMutation, { headers })
            .then((response) => {
                console.log("Add Truck Response", response)
                setMessageType(response.data?.data.addTruck.type);
                setShowModal(true);
                if (response.data?.data.addTruck.type == "success") {
                    setTruckError(response.data?.data.addTruck.message);
                    setTimeout(() => {
                        reset()
                        setShowModal(false)
                        handleCloseAddTruck(false)
                    }, 3000);
                }
                if (response.data?.data.addTruck.type == "failed") {
                    if (response.data.data.addTruck.error.truck_number != null) {
                        setTruckError(response.data?.data.addTruck.error.truck_number[0]);
                    } else if (response.data.data.addTruck.error.ownership != null) {
                        setTruckError(response.data?.data.addTruck.error.ownership[0]);
                    } else if (response.data.data.addTruck.error.body_type != null) {
                        setTruckError(response.data?.data.addTruck.error.body_type[0]);
                    }
                    setTimeout(() => {
                        setShowModal(false)
                    }, 4000);

                }
            })
            .catch((error) => {
                console.log("Add Truck Error", error)
            })
    };

    const truckTypeData = [
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/lcv.png", truckName: "Mini Truck / LCV" },
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/open_truck.png", truckName: "Open Body Truck" },
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/closed_truck.png", truckName: "Closed Container" },
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/trailer.png", truckName: "Trailer" },
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/tanker.png", truckName: "Tanker" },
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/tipper.png", truckName: "Tipper" },
        { truckIcon: "https://tpbook.s3.ap-south-1.amazonaws.com/app_statics/vehicle_types_static_icons/drawable-xxxhdpi/bus.png", truckName: "Other" }
    ]

    useEffect(() => { }, [])
    const handleTruckType = (name) => {
        console.log('name', name);
        setSelectedTruckType(name);
        setValue('body_type', name);
    };
    return (
        <>
            <Modal show={show} onHide={() => handleCloseAddTruck(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Truck</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="p-2" onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-4'>
                            <label htmlFor="truck_number" className='mb-2'>Truck Number <sup><i className="bi bi-asterisk my-icon"></i></sup></label>
                            <div className="d-flex">
                                <div className="py-2 px-3 form_icon_icon font-weight-bold">
                                    <i className="bi bi-truck"></i>
                                </div>
                                <input
                                    onInput={(e) => (e.target.value = ("" + e.target.value).toUpperCase())}
                                    type="text"
                                    className="form-control_icon"
                                    id="truck_number"
                                    aria-describedby="emailHelp"
                                    {...register("truck_number", {
                                        required: true,
                                        pattern: /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$|^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/,
                                    })}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="truck_type" className='mb-3'>Truck Type <sup><i className="bi bi-asterisk my-icon"></i></sup></label>
                            <div className=' MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-align-items-xs-center MuiGrid-justify-content-xs-center ' >
                                {truckTypeData?.map((el, index) => (
                                    <div
                                        key={index}
                                        className={`MuiGrid-root jss1838 MuiGrid-item MuiGrid-grid-xs-3 ${selectedTruckType === el.truckName ? 'active' : ''
                                            }`}
                                        tabIndex={index}
                                        onClick={() => handleTruckType(el.truckName)}
                                    >

                                        <img src={el.truckIcon} alt={el.truckName} style={{ width: "32px", textAlign: "center" }} />
                                        <p>{el.truckName}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr />
                        <div className='mb-2'>
                            <label className='mb-2'>Ownership</label>
                            <div className='d-flex justify-content-around'>
                                <label>
                                    <input type="radio" name="truck_ownership" value="Market Truck" {...register("truck_ownership", {})} />
                                    &nbsp; Market Truck
                                </label>
                                <label>
                                    <input type="radio" name="truck_ownership" value="My Truck" {...register("truck_ownership", {})} />
                                    &nbsp; My Truck
                                </label>
                            </div>
                        </div>
                        <hr />
                        <Button type="submit">Submit</Button>
                    </form>
                </Modal.Body>
            </Modal>
            {showModal && (
                <CustomizedSnackbars
                    show={showModal}
                    message={truckError}
                    messageType={messageType}
                />
            )}
        </>
    )
}

export default AddTruckModal