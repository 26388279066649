import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedConsigner: "",
  selectedConsignee: "",
  selectedLrNumber: "",
  selectedInvoiceNumber: "",
  selectedSource: "",
  selectedDestination: "",
  selectedDate: "",
};

export const filterComponents = createSlice({
  name: "filterComponents",
  initialState,
  reducers: {
    setSelectedConsigner: (state, action) => {
      state.selectedConsigner = action.payload;
    },
    setSelectedConsignee: (state, action) => {
      state.selectedConsignee = action.payload;
    },
    setSelectedLrNumber: (state, action) => {
      state.selectedLrNumber = action.payload;
    },
    setSelectedInvoiceNumber: (state, action) => {
      state.selectedInvoiceNumber = action.payload;
    },
    setSelectedSource: (state, action) => {
      state.selectedSource = action.payload;
    },
    setSelectedDestination: (state, action) => {
      state.selectedDestination = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
  },
});

export const {
  setSelectedConsigner,
  setSelectedConsignee,
  setSelectedLrNumber,
  setSelectedInvoiceNumber,
  setSelectedSource,
  setSelectedDestination,
  setSelectedDate,
} = filterComponents.actions;
export default filterComponents.reducer;
