import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentStep: 1,
    details: {},
    contact: {},
    licenceDetails: {},
    references: {},
};

export const driverFormReducer = createSlice({
    name: "driverFormReducer",
    initialState,
    reducers: {
        setIncrementStep: (state, action) => {
            if (state.currentStep > 0 && state.currentStep < 5) {
                state.currentStep += 1;
                if (action.payload.driver_name_) {
                    state.details = action.payload;
                }
                if (action.payload.driver_blood_group) {
                    state.contact = action.payload;
                }
                if (action.payload.r_name) {
                    state.references = action.payload;
                }
                if (action.payload.driver_class) {
                    state.licenceDetails = action.payload;
                }

            }
            else {
                state.currentStep = 1;
                state.bank = action.payload;
                state.details = {};
            }

        },
        setDecrementStep: (state) => {
            if (state.currentStep != 1 && state.currentStep < 6) {
                state.currentStep -= 1;
            }
        },
        setDriverStates: (state) => {
            state.contact = {};
            state.licenceDetails = {};
            state.references = {};
            state.details = {};
            state.currentStep = 1;
        }
    }
}
);

export const { setIncrementStep, setDecrementStep, setDriverStates } = driverFormReducer.actions;
export default driverFormReducer.reducer;
