import React from "react";
import { Modal } from "react-bootstrap";
import AddConsignee from "../Add/AddConsignee";
import AddConsigner from "../Add/AddConsigner";
import AddProduct from "../Add/AddProduct";

const AddModal = (props) => {
  return (
    <>
      <Modal show={props?.modalHandler} size="xl">
        <Modal.Header
          className="mb-4"
          style={{
            backgroundColor: "#10233c",
            color: "white",
            height: "40px",
            textAlign: "center",
          }}
        >
          <h4 className=" my-0 m-auto">{`Add ${" "} ${props?.addType}`}</h4>
        </Modal.Header>
        <Modal.Body>
          {props?.addType == "Consigner" && <AddConsigner />}
          {props?.addType == "Consignee" && <AddConsignee />}
          {props?.addType == "Product" && <AddProduct />}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark"
            onClick={() => props?.modalAction(false)}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddModal;
