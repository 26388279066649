import React, { useState } from "react";

function ToastBox({ toggle, message, messageType }) {

  const [show, setShow] = useState(toggle);

  return (
    <>
      <div className="mt-3">
        {messageType == "success" ? (
          <div
            className=" alert alert-success d-flex justify-content-center"
            role="alert"
          >
            {message}
          </div>
        ) : (
          <div
            className="alert alert-danger d-flex justify-content-center"
            role="alert"
          >
            {message}
          </div>
        )}
      </div>
    </>
  );
}

export default ToastBox;
