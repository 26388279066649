import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../../config";
import DocModal from "../../common/DocModal/DocModal";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TruckDocuments from "./AddTruck/TruckDocuments";

const TruckModal = (props) => {
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [url, setUrl] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [modalData, setModalData] = useState();
  const [show, setShow] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [responseData, setResponseData] = useState("")
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
  };


  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
      }, 5000);
    }
  }, [show, firm_id]);

  useEffect(() => {
    const formData = props?.data;
    setModalData(formData?.find((el) => props?.truckId === el.truck_id));
  }, [props]);

  useEffect(() => {
    if (props.data) {
      setValue("truck_number", modalData?.truck_number || null);
      setValue("ownership", modalData?.ownership || null);
      setValue("body_type", modalData?.body_type || null);
      setValue("invoice", modalData?.invoice || null);
      setValue("chasis_number", modalData?.chasis_number || null);
      setValue("engine_number", modalData?.engine_number || null);
      setValue("fastag_number", modalData?.fastag_number || null);
      setValue("gross_weight", modalData?.gross_weight || null);
      setValue("new_registration", modalData?.new_registration || null);
      setValue("payloading_weight", modalData?.payloading_weight || null);
      setValue("purchased_date", modalData?.purchased_date || null);
      setValue("registration_date", modalData?.registration_date || null);
      setValue("rto", modalData?.rto || null);
      setValue("total_cost", modalData?.total_cost || null);
      setValue("trolley_length", modalData?.trolley_length || null);
      setValue("truck_length", modalData?.truck_length || null);
      setValue("unloading_weight", modalData?.unloading_weight || null);
      setValue("valid_upto", modalData?.valid_upto || null);
      setValue("manufacturing_date", modalData?.manufacturing_date || null);
    }
  }, [modalData]);

  const closeModal = (type) => {
    setIsModalOpen(type);
  };

  const handleAddMoreDetails = () => {
    setAddMore(!addMore);
  };

  const onSubmitTruck = (data) => {
    setLoading(true)
    console.log("data", data);

    const UpdateTruckMutation = {
      query: `mutation(
              $firm_id:ID!,
              $truck_id:ID!,
              $truck_number:String!,
              $model:String,
              $old_registration:String,
              $new_registration:String
              $manufacturing_year:String
              $valid_upto:Date
              $ownership:String!,
              $registration_date:Date
              $fastag_number:String
              $rto:String,
              $engine_number:String
              $chasis_number:String
              $truck_length:Int
              $trolley_length:Int
              $gross_weight:Int
              $unloading_weight:Int
              $payloading_weight:Int
              $purchased_date:Date
              $invoice:Date
              $total_cost:Int
              $model_type:String
              $body_type:String!,
              ){
         updateTruck(
                    firm_id:$firm_id,
                    truck_id:$truck_id,
                    truck_number:$truck_number,
                    model:$model,
                    old_registration:$old_registration,
                    new_registration:$new_registration,
                    manufacturing_year:$manufacturing_year,
                    valid_upto:$valid_upto,
                    ownership:$ownership,
                    registration_date:$registration_date,
                    fastag_number:$fastag_number,
                    rto:$rto,
                    engine_number:$engine_number,
                    chasis_number:$chasis_number,
                    truck_length:$truck_length,
                    trolley_length:$trolley_length,
                    gross_weight:$gross_weight,
                    unloading_weight:$unloading_weight,
                    payloading_weight:$payloading_weight,
                    purchased_date:$purchased_date,
                    invoice:$invoice,
                    total_cost:$total_cost,
                    model_type:$model_type
                    body_type:$body_type,
                    ){
                    type
                    message
                      error{
                        firm_id
                        truck_id
                        truck_number
                        ownership
                        body_type
                      }
                  }
                }`,
      variables: {
        firm_id: +firm_id,
        truck_id: +props?.truckId,
        truck_number: data.truck_number,
        model: data?.model,
        old_registration: data?.old_registration,
        new_registration: data?.new_registration,
        manufacturing_year: data?.manufacturing_year,
        valid_upto: data?.valid_upto,
        ownership: data?.ownership,
        registration_date: data?.registration_date,
        fastag_number: data?.fastag_number,
        rto: data?.rto,
        engine_number: data?.engine_number,
        chasis_number: data?.chasis_number,
        truck_length: parseInt(data?.truck_length),
        trolley_length: parseInt(data?.trolley_length),
        gross_weight: parseInt(data?.gross_weight),
        unloading_weight: parseInt(data?.unloading_weight),
        payloading_weight: parseInt(data?.payloading_weight),
        purchased_date: data?.purchased_date,
        invoice: data?.invoice,
        total_cost: parseInt(data?.total_cost),
        model_type: data?.model_type,
        body_type: data?.body_type,

      }
    }

    axios.post(BASE_URL, UpdateTruckMutation, { headers })
      .then((response) => {
        setMessageType(response?.data?.data?.updateTruck.type)
        setLoading(false)
        setShow(true);
        if (response?.data?.data?.updateTruck.type == "success") {
          setResponseData(response?.data?.data?.updateTruck.message)
          setAddMore(false)
          setTimeout(() => { props?.modalAction(false) }, 3000)
        }
        else {
          setResponseData(response?.data?.data?.updateTruck.error)
        }
      })
      .catch((error) => {
        console.log("error_update-truck", error)
      })
  };

  return (
    <>
      <Modal show={props?.modalHandler} size="lg" scrollable={true}>
        <Modal.Header
          className="mb-4"
          style={{
            backgroundColor: "#10233c",
            color: "white",
            height: "40px",
            textAlign: "center",
          }}
        >
          <h4 className=" my-0 m-auto">Truck Details</h4>
        </Modal.Header>
        <Modal.Body>
          <div className='tabs-sec'>
            <Tabs
              defaultActiveKey="details"
              id="uncontrolled-tab-example"
              className="mb-4"
              justify
            >
              <Tab eventKey="details" title="Details">

                <Form onSubmit={handleSubmit(onSubmitTruck)}>
                  <p className="mx-3 basic_datails">
                    <b> Basic Details </b>
                    <b className="text-decoration-underline add_more" onClick={handleAddMoreDetails} >{addMore == false ? "+ Add More Details" : <><i className="bi bi-arrow-left" /> Back</>}</b>
                  </p>

                  <Form.Group className="form-group">
                    <Form.Label>Truck Number</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.truck_number}
                      type="text"
                      {...register("truck_number", {
                        required: true,
                        pattern:
                          /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$|^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/,
                      })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Ownership</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        disabled={addMore ? false : true}
                        defaultValue={modalData?.ownership}
                        type="text"
                        {...register("ownership", {
                          required: true,
                        })}
                      />

                    </div>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Body Type</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        disabled={addMore ? false : true}
                        defaultValue={modalData?.body_type}
                        type="text"
                        {...register("body_type", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.body_type &&
                      errors.body_type?.type === "required" && (
                        <p className="error_p">Body Type is required</p>
                      )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.new_registration != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Registration Year</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.new_registration}
                      type="text"
                      placeholder="XXXX"
                      {...register("new_registration", {
                        pattern: /[0-9]/g,
                        maxLength: 4,
                        minLength: 4,
                      })}
                    />
                  </Form.Group>

                  <Form.Group hidden={(modalData?.manufacturing_year != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Manufacturing Year</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.manufacturing_year
                        ?.split("-")
                        .reverse()
                        .join("-")}
                      type="text"
                      placeholder="XXXX"
                      {...register("manufacturing_year", {
                      })}
                    />
                  </Form.Group>

                  <Form.Group hidden={(modalData?.valid_upto != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Valid Upto</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.valid_upto
                        ?.split("-")
                        .reverse()
                        .join("-")}
                      type="date"
                      {...register("valid_upto", {
                      })}
                    />
                  </Form.Group>

                  <Form.Group hidden={(modalData?.registration_date != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Registration Date</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.registration_date
                        ?.split("-")
                        .reverse()
                        .join("-")}
                      type="date"
                      {...register("registration_date", {
                      })}
                    />

                  </Form.Group>

                  <Form.Group hidden={(modalData?.fastag_number != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Fastag Number</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.fastag_number}
                      type="text"
                      {...register("fastag_number", {
                        pattern: /[0-9]/,
                        minLength: 16,
                        maxLength: 16,
                      })}
                    />
                  </Form.Group>

                  <Form.Group hidden={(modalData?.rto != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>RTO</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.rto}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      type="text"
                      {...register("rto", {
                        minLength: 4,
                        maxLength: 4,
                        pattern: /^[A-Z]{2}\d{2}$/,
                      })}
                    />
                    {errors.rto && errors.rto?.type === "pattern" && (
                      <p className="error_p">
                        Enter a valid RTO number
                      </p>
                    )}
                    {errors.rto && (errors.rto?.type === "minLength" || errors.rto?.type === "maxLength") && (
                      <p className="error_p">
                        Two uppercase letters followed by two digits
                      </p>
                    )}

                  </Form.Group>

                  <Form.Group hidden={(modalData?.engine_number != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Engine Number</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.engine_number}
                      type="text"
                      {...register("engine_number", {
                        pattern: /[0-9]/g,
                      })}
                    />
                  </Form.Group>

                  <Form.Group hidden={(modalData?.chasis_number != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Chassis Number</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.chasis_number}
                      type="text"
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                      {...register("chasis_number", {
                        minLength: 17,

                      })}
                    />

                    {errors.chasis_number &&
                      (errors.chasis_number?.type === "minLength" &&
                        <p className="error_p">
                          Chassis Number should contain at least 17 characters
                        </p>
                      )}
                  </Form.Group>

                  <Form.Group
                    hidden={(modalData?.truck_length != null || addMore == true) ? false : true}
                    className="form-group">
                    <Form.Label>Truck Length / Feet</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        disabled={addMore ? false : true}
                        defaultValue={modalData?.truck_length}
                        type="number"
                        onChangeCapture={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, '');
                          setValue("truck_length", value);
                        }}
                        {...register("truck_length", {
                          pattern: /[0-9]/g,
                        })}
                      />
                      {errors?.truck_length?.type === "pattern" && (
                        <p className="error_p">Truck Length must be a number</p>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="form-group"
                    hidden={(modalData?.trolley_length != null || addMore == true) ? false : true}
                  >
                    <Form.Label>Trolley Length / Feet</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.trolley_length}
                      type="number"
                      onChangeCapture={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setValue("trolley_length", value);
                      }}
                      {...register("trolley_length", {
                        pattern: /[0-9]/g,
                      })}
                    />

                    {errors?.trolley_length?.type === "pattern" && (
                      <p className="error_p">Trolly Length must be a number</p>
                    )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.gross_weight != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Gross Weight / Kg</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.gross_weight}
                      type="text"
                      onChangeCapture={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setValue("gross_weight", value);
                      }}
                      {...register("gross_weight", {

                        pattern: /[0-9]/g,
                      })}
                    />
                    {errors?.gross_weight?.type === "required" && (
                      <p className="error_p">Gross Weight is required</p>
                    )}
                    {errors?.gross_weight?.type === "pattern" && (
                      <p className="error_p">Gross Weight must be a number</p>
                    )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.unloading_weight != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Unloading Weight / Kg</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.unloading_weight}
                      type="text"
                      onChangeCapture={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setValue("unloading_weight", value);
                      }}
                      {...register("unloading_weight", {

                        pattern: /[0-9]/g,
                      })}
                    />
                    {errors?.unloading_weight?.type === "required" && (
                      <p className="error_p">Unloading weigth is required</p>
                    )}
                    {errors?.unloading_weight?.type === "pattern" && (
                      <p className="error_p">Unloading weigth must be a number</p>
                    )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.payloading_weight != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Payloading Weight / Kg</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.payloading_weight}
                      type="text"
                      onChangeCapture={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setValue("payloading_weight", value);
                      }}
                      {...register("payloading_weight", {

                        pattern: /[0-9]/g,
                      })}
                    />
                    {errors?.payloading_weight?.type === "required" && (
                      <p className="error_p">Pay load weight is required</p>
                    )}
                    {errors?.payloading_weight?.type === "pattern" && (
                      <p className="error_p">Pay load weight is required</p>
                    )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.purchased_date != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Purchased Date</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.purchased_date
                        ?.split("-")
                        .reverse()
                        .join("-")}
                      type="date"
                      {...register("purchased_date", {

                      })}
                    />
                    {errors?.purchased_date?.type === "required" && (
                      <p className="error_p">Purchase Date is required</p>
                    )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.invoice != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Invoice Date</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      defaultValue={modalData?.invoice}
                      type="Date"
                      {...register("invoice", {

                      })}
                    />
                    {errors?.invoice?.type === "required" && (
                      <p className="error_p">Invoice Date is required</p>
                    )}
                  </Form.Group>

                  <Form.Group hidden={(modalData?.total_cost != null || addMore == true) ? false : true} className="form-group">
                    <Form.Label>Total Cost</Form.Label>
                    <Form.Control
                      disabled={addMore ? false : true}
                      onChangeCapture={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        setValue("total_cost", value);
                      }}
                      defaultValue={modalData?.total_cost}
                      type="text"
                      {...register("total_cost", {

                        pattern: /[0-9]/g,
                      })}
                    />
                    {errors?.total_cost?.type === "required" && (
                      <p className="error_p">Total cost is required</p>
                    )}

                    {errors?.total_cost?.type === "pattern" && (
                      <p className="error_p">Total cost must be a number</p>
                    )}
                  </Form.Group>


                  <div style={{ clear: "both" }}></div>

                  {addMore == true && <> <hr />
                    <Button type="submit" className="dark-btn">Submit</Button>
                  </>
                  }
                </Form>
              </Tab>
              <Tab eventKey="documents" title="Documents">
                <TruckDocuments truck_id={+props?.truckId} />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props?.modalAction(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {show && (
        <CustomizedSnackbars
          show={show}
          message={responseData}
          messageType={messageType}
        />
      )}
    </>
  );
};

export default TruckModal;
