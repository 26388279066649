import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import SimpleBackdrop from '../../common/Spinner/Spinner';
import { createColumnHelper } from '@tanstack/react-table';
import TanStackTable from '../../common/Table/TanStackTable';
import { Button } from 'react-bootstrap';
import { BASE_URL } from '../../../config';
import { setLogout } from '../../../features/Login';
import DeleteFirm from './DeleteFirm';
import DocModal from '../../common/DocModal/DocModal';
import EditFirms from './EditFirm';
import FirmDocModal from './FirmDocModal';

function Firms() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const userId = useSelector((state) => state?.loginDetail?.userDetail?.id);
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState("");
  const columnHelper = createColumnHelper();
  const [url, setUrl] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [firmForDelete, setfirmForDelete] = useState();
  const [firmDocModal, setFirmDocModal] = useState(false);
  const [editFirm, setEditFirm] = useState()
  const [editFirmModal, setEditFirmModal] = useState(false)

  const columns = [
    columnHelper.accessor('firm_name', {
      header: 'Name'
    }),
    columnHelper.accessor('firm_contact', {
      header: 'Contact'
    }),
    columnHelper.accessor('firm_email', {
      header: 'E-Mail'
    }),
    columnHelper.accessor('firm_address', {
      header: 'Address'
    }),
    columnHelper.accessor('firm_gstin', {
      header: 'GST'
    }),
    columnHelper.accessor('firm_pancard', {
      header: 'Pancard'
    }),
    columnHelper.accessor('firm_cin', {
      header: 'CIN',
      cell: info => {
        let data = info.getValue()
        return data != null ? data : "-"
      },
    }),
    columnHelper.accessor('firm_id', {
      header: 'Licence',
      cell: (props) => <button type="button" value={props.getValue()} className="dark-btn m-auto bi bi-eye rounded" onClick={handleDownload}></button>
    }),
    columnHelper.accessor('firm_id', {
      header: 'Update',
      cell: (props) => (
        <div className='d-flex'>
          <button
            type="button"
            value={props.getValue()}
            className="light-btn rounded m-auto bi bi-pen-fill"
            onClick={handleEditFirm}
          ></button>
          <button
            type="button"
            value={props.getValue()}
            className="light-btn rounded m-auto bi bi-trash3-fill"
            onClick={handleDeleteFirm}
          ></button>
        </div >
      ),
    }),
  ];

  useEffect(() => {
    const fetchFirmData = async () => {
      try {
        const headers = {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        };
        const query = {
          query: `query($user_id:ID!,$isFirmDeleted:Boolean!){
          firm(user_id:$user_id , isFirmDeleted:$isFirmDeleted){
            firm_id
            firm_name
            firm_email
            firm_contact
            firm_address
            firm_pancard
            firm_gstin
            firm_licence
            firm_cin
          }
        }`,
          variables: {
            user_id: userId,
            isFirmDeleted: false
          }
        }

        const response = await axios.post(BASE_URL, query, { headers });
        if (response.data?.errors?.[0]?.message === "Unauthenticated.") {
          dispatch(setLogout());
        }
        if (response.data?.data?.firm?.length) {
          setContent(response.data.data.firm);
        } else {
          setContent([]);
        }
        setLoading(false);
      } catch (error) {
        setIsError(error.message);
        setLoading(false);
      }
    };

    fetchFirmData();
  }, [userId, bearerToken, showDeleteModal, editFirmModal]);


  const handleDownload = async (e) => {
    try {
      const FirmLicenceQuery = {
        query: `query($firm_id:ID!){
          firmLicence(firm_id:$firm_id)
          {
            url
          }
        }`,
        variables: { firm_id: e.target.value }
      }
      const response = await axios.post(BASE_URL, FirmLicenceQuery);
      setUrl(response.data?.data?.firmLicence?.url);
      setFirmDocModal(true);
    } catch (error) {
      console.error("Error downloading firm licence:", error);
    }
  }

  const handleClose = (type) => setShowDeleteModal(type);
  const closeModalEdit = (type) => {
    setEditFirmModal(type)
  }

  const handleDeleteFirm = (e) => {
    setShowDeleteModal(true)
    setfirmForDelete(e.target.value)
  }
  const handleEditFirm = (e) => {
    const data = content?.find((el) => e.target.value == el.firm_id)
    setEditFirm(data)
    setEditFirmModal(true)
  }

  const handleFirmDocModal = (type) => {
    setFirmDocModal(type)
  }

  return (
    <>
      <div className='mb-4'
        style={{
          backgroundColor: "#10233c",
          color: "white",
          height: "40px",
          textAlign: "center",
        }}
      >
        <h4 className="pt-1">Firm Details</h4>
      </div>

      <Link to="/addfirms" className='btn dark-btn'>
        Add Firms
      </Link>
      <hr />
      {loading
        ?
        <SimpleBackdrop />
        :
        (content?.length > 0
          ?
          <TanStackTable data={content} columns={columns} />
          :
          <div className="d-flex aline-item-center pt-2 no_data-img">
            <img src="/no_data.png"></img>
          </div>
        )

      }
      <DeleteFirm show={showDeleteModal} handleClose={handleClose} firmForDelete={firmForDelete} userId={userId} />
      <FirmDocModal show={firmDocModal} url={url} handleFirmDocModal={handleFirmDocModal} />
      <EditFirms closeModalEdit={closeModalEdit} data={editFirm} editFirmModal={editFirmModal} />
    </>
  )
}

export default Firms;