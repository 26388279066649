import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Stack spacing={2} sx={{ width: "300px" }}>
        <Snackbar
          open={props.show}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            severity={props.messageType == "success" ? "success" : "error"}
            sx={{ width: "300px" }}
          >
            {props.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}
