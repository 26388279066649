import React from "react";
import Form from "react-bootstrap/Form";
import "../AddDriver.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setDecrementStep,
  setIncrementStep,
} from "../../../../features/DriverForm";
import { capitalizeInput } from "../../../common/FunctionsC/functionsC";
function References() {
  const dispatch = useDispatch();
  const references = useSelector((state) => state?.driverFormReducer?.references);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const onSubmit = (data) => {
    dispatch(setIncrementStep(data));
  };
  const BackStep = () => {
    dispatch(setDecrementStep());
  };

  const handleCapture = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("r_phone_no", result);
  };

  return (
    <>
      <div className="mt-5">
        <Form className="adddriver-form p-3" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="form-group " controlId="">
            <Form.Label>Name </Form.Label>
            <Form.Control
              onInput={(e) => capitalizeInput(e)}
              defaultValue={references.r_name}
              type="text"
              {...register("r_name", {})}
            />
          </Form.Group>

          <Form.Group className="form-group " controlId="">
            <Form.Label>Phone </Form.Label>
            <Form.Control
              onChangeCapture={handleCapture}
              defaultValue={references.r_phone_no}
              type="text"
              {...register("r_phone_no", {
                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
              })}
            />

            {errors?.r_phone_no?.type === "pattern" && (
              <p className="error_p">
                Please entre a valid 10 digit phone number
              </p>
            )}
          </Form.Group>

          <Form.Group className="form-group " controlId="">
            <Form.Label>Address </Form.Label>
            <Form.Control
              onInput={(e) => capitalizeInput(e)}
              defaultValue={references.r_address}
              type="text"
              {...register("r_address", {})}
            />

          </Form.Group>

          <Form.Group className="form-group " controlId="">
            <Form.Label>Relation </Form.Label>
            <Form.Control
              onInput={(e) => capitalizeInput(e)}
              defaultValue={references.r_relation}
              type="text"
              {...register("r_relation", {
              })}
            />

          </Form.Group>

          <div style={{ clear: "both" }}></div>
          <div>
            <button type="submit" className="mt-3 btn-next ">
              <h1>
                <i className="bi bi-arrow-right-square-fill"></i>
              </h1>
            </button>
            <button className="mt-3 btn-back " onClick={BackStep}>
              <h1>
                <i className="bi bi-arrow-left-square-fill"></i>
              </h1>
            </button>
          </div>
          <div style={{ clear: "both" }}></div>
        </Form>
      </div>
    </>
  );
}
export default References;
