import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setDecrementStep,
  setIncrementStep,
} from "../../../../features/DriverForm";
import { State, City } from "country-state-city";
import { capitalizeInput } from "../../../common/FunctionsC/functionsC";

function Contacts() {
  const dispatch = useDispatch();
  const contact = useSelector((state) => state?.driverFormReducer?.contact);
  const [blockCheck, setBlockCheck] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const handleStateChange = (event) => {
    const stateCode = event.target.value;
    console.log("stateCode", stateCode);
    setSelectedState(stateCode);
    const stateCities = City.getCitiesOfState("IN", stateCode);
    setCities(stateCities);
  };
  const indianStates = State.getStatesOfCountry("IN");
  const onSubmit = (data) => {
    dispatch(setIncrementStep(data));
  };
  const BackStep = () => {
    dispatch(setDecrementStep());
  };

  const handleChange = (e) => {
    setBlockCheck(e.target.checked);
  };

  useEffect(() => {
    if (blockCheck) {
      setValue("driver_block_reason", "");
    } else {
      setValue("driver_block_reason", "None");
    }
  }, [blockCheck]);


  const handleCapturePhone = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("driver_phone_no", result);
  };
  const handleCapturePin = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("driver_pin", result);
  };
  return (
    <>
      <div className="mt-5">
        <Form className="adddriver-form p-3" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="form-group" controlId="">
            <Form.Label>Address <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Control
              defaultValue={contact.driver_address}
              type="text"
              onInput={(e) => capitalizeInput(e)}
              {...register("driver_address", {
                required: true,
              })}
            />
            {errors?.driver_address?.type === "required" && (
              <p className="error_p">Address is required</p>
            )}
          </Form.Group>

          <Form.Group className="form-group" controlId="">
            <Form.Label>Phone <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Control
              defaultValue={contact.driver_phone_no}
              type="text"
              onChangeCapture={handleCapturePhone}
              {...register("driver_phone_no", {
                required: true,
                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
              })}
            />
            {errors?.driver_phone_no?.type === "required" && (
              <p className="error_p">Phone number is required</p>
            )}
            {errors?.driver_phone_no?.type === "pattern" && (
              <p className="error_p">
                Please entre a valid 10 digit phone number
              </p>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="">
            <Form.Label>State <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Select
              defaultValue={contact.driver_state}
              value={selectedState}
              onChangeCapture={handleStateChange}
              aria-label="Default select example"
              {...register("driver_state", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              {indianStates?.map((state) => (
                <option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </Form.Select>
            {errors?.driver_state?.type === "required" && (
              <p className="error_p">State is required</p>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="">
            <Form.Label>City <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Select
              defaultValue={contact.driver_city}
              aria-label="Default select example"
              {...register("driver_city", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              {cities?.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              ))}
            </Form.Select>
            {errors?.driver_city?.type === "required" && (
              <p className="error_p">City is required</p>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="">
            <Form.Label>PIN <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Control
              defaultValue={contact.driver_pin}
              type="text"
              onChangeCapture={handleCapturePin}
              {...register("driver_pin", {
                required: true,
                pattern: /^[0-9]{6}$/,
              })}
            />
            {errors?.driver_pin?.type === "required" && (
              <p className="error_p">Pin code is required</p>
            )}
            {errors?.driver_pin?.type === "pattern" && (
              <p className="error_p">Please enter valid Pin code</p>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="">
            <Form.Label>Blood Group <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Select
              defaultValue={contact.driver_blood_group}
              aria-label="Default select example"
              {...register("driver_blood_group", {
                required: true,
              })}
            >
              <option value="">- Select -</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </Form.Select>
            {errors?.driver_blood_group?.type === "required" && (
              <p className="error_p">Blood group is required</p>
            )}
          </Form.Group>

          <Form.Group
            className="form-group d-flex align-items-center width_66"
            controlId=""
          >
            <div className="d-flex align-items-center mt-4 pt-2">
              <Form.Check
                //  onClick={(event) => setCheck(!check)}
                onClickCapture={handleChange}
                aria-label="option 1"
                {...register("driver_blacklisted")}
              />
              <Form.Label className="ms-2 mb-0"> Blacklisted </Form.Label>
            </div>


            {blockCheck == true && (
              <div className="reason w-100 ps-5">
                <Form.Label>Reason for Black list</Form.Label>
                <Form.Control
                  defaultValue={contact.driver_block_reason}
                  disabled={blockCheck == false}
                  type="text"
                  {...register("driver_block_reason", {
                    required: true,
                  })}
                />
                {errors?.driver_block_reason?.type === "required" && (
                  <p className="error_p">Block reason is required</p>
                )}
              </div>


            )}
          </Form.Group>
          <div style={{ clear: "both" }}></div>
          <div style={{ clear: "inline-start" }}></div>
          <div>
            <button type="submit" className="mt-3 btn-next ">
              <h1>
                <i className="bi bi-arrow-right-square-fill"></i>
              </h1>
            </button>
            <button className="mt-3 btn-back " onClick={BackStep}>
              <h1>
                <i className="bi bi-arrow-left-square-fill"></i>
              </h1>
            </button>
          </div>
          <div style={{ clear: "both" }}></div>
        </Form>
      </div>
    </>
  );
}

export default Contacts;
