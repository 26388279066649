import React, { useState, useEffect } from "react";
import "./LoadTruck.css";
import "./../../common/Form/Form.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { Form } from "react-bootstrap";
import { setIncrem } from "../../../features/TruckForm";
import { City } from "country-state-city";
import { BASE_URL } from "../../../config";
import { NavLink } from "react-router-dom";
import { setLogout } from "../../../features/Login";
import AddModal from "./AddModal";
import CreatableSelect from 'react-select/creatable';
import SourceFilter from "../Filters/SourceFilter";
import DestinationFilter from "../Filters/DestinationFilter";

function TripDetail() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const truckDetails = useSelector((state) => state?.truckFormReducer?.truckDetails);
  const TodayDate = moment().format("YYYY-MM-DD");
  const [truckData, setTruckData] = useState([]);
  const [consignerData, setConsignerData] = useState([]);
  const [consigneeData, setConsigneeData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [addType, setAddType] = useState()
  const [loading, setLoading] = useState(false);
  const [modalHandler, setModalHandler] = useState(false)
  const cities = City.getCitiesOfCountry("IN");

  const CitiesOption = cities?.map((city) => {
    return { value: city.name, label: city.name };
  });
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
  };
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    setLoading(true);

    // ===== Query to get Firm's Truck Number ==========
    const truckQuery = {
      query: `query($firm_id:ID!,$isTruckDeleted:Boolean!,$truck_status:String!){
                truck(firm_id:$firm_id,isTruckDeleted:$isTruckDeleted,truck_status:$truck_status){
                    truck_number
                    truck_id
                    truck_status
                }
              }`,
      variables: {
        firm_id: firm_id,
        isTruckDeleted: false,
        truck_status: "Idle"
      },
    };
    axios
      .post(BASE_URL, truckQuery, { headers })
      .then((response) => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout());
        } else {
          setTruckData(response.data?.data.truck);
          setLoading(false);
        }
      })
      .catch((error) => { });

    // ====== Query to get Firm's Consigners ============
    const consignerQuery = {
      query: `query($firm_id:ID!){
                consigner(firm_id:$firm_id)
                {
                  consigner_name
                }
              }`,
      variables: {
        firm_id: firm_id,
      },
    };
    axios
      .post(BASE_URL, consignerQuery, { headers })
      .then((response) => {
        setConsignerData(response.data?.data.consigner);
      })
      .catch((error) => { });

    // ===== Query to get Firms's Consignees ========
    const consigneeQuery = {
      query: `query($firm_id:ID!){
                consignee(firm_id:$firm_id)
                {
                  consignee_id
                  consignee_name
                  
                }
              }`,
      variables: {
        firm_id: firm_id,
      },
    };
    axios
      .post(BASE_URL, consigneeQuery, { headers })
      .then((response) => {
        setConsigneeData(response.data?.data.consignee);
      })
      .catch((error) => { });

    // ========= Query to get Firm's Products ======

    const productQuery = {
      query: `query($firm_id:ID!){
        product(firm_id:$firm_id)
        {
          product_id
          firm_id
          product_name
        }
      }`,
      variables: {
        firm_id: firm_id,
      },
    };
    axios
      .post(BASE_URL, productQuery, { headers })
      .then((response) => {
        setProductData(response.data?.data?.product)
      })
      .catch((error) => {

      })
  }, [firm_id, modalHandler]);

  const onSubmit = (data) => {
    dispatch(setIncrem(data));
  };

  const handleMoreDetailsView = (e, target) => {
    e.preventDefault();
    setModalHandler(true)
    setAddType(target)
  }

  const ModelAction = (modalHandler) => {
    setModalHandler(modalHandler)
  }


  return (
    <>
      <div>
        <Form
          className="adddriver-form  mt-5 p-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group className="form-group" controlId="">
            <Form.Label>Date <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Control
              className="w-50"
              defaultValue={truckDetails.trip_date}
              type="date"
              max={TodayDate}
              {...register("trip_date", {
                required: true,
              })}
            />
            {errors?.trip_date?.type === "required" && (
              <p className="error_p">Date is required</p>
            )}
          </Form.Group>
          <div style={{ clear: "both" }}></div>

          <Form.Group className="form-group">
            <Form.Label>Truck Number <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <Form.Select
              defaultValue={truckDetails?.truck_id}
              {...register("truck_id", {
                required: true,
              })}
            >
              <option value="">-Select-</option>
              {truckData?.map((t) => (
                <option option value={t.truck_id} > {t.truck_number}</option>
              ))}
            </Form.Select>
            {errors?.truck_id?.type === "required" && (
              <p className="error_p">Truck Number is required</p>
            )}
          </Form.Group>



          <div className='form-group' >
            <label className="mb-2">Source <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
            <SourceFilter />
          </div>
          <div className='form-group'>
            <label className="mb-2">Destination <sup><i class="bi bi-asterisk my-icon"></i></sup></label>
            <DestinationFilter
            />
          </div>

          <Form.Group className="form-group " style={{ clear: "both" }} controlId="">


            <Form.Label>Consigner <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <div className="d-flex">
              <Form.Select
                style={{
                  borderRadius: "0px",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
                defaultValue={truckDetails.trip_consigner}
                type="text"
                {...register("trip_consigner", {
                  required: true,
                })}
              >
                <option value="">-Select-</option>
                {loading && <option value="">...Loading</option>}
                {consignerData?.map((c) => (
                  <option>{c.consigner_name}</option>
                ))}
              </Form.Select>
              <div className="py-0 px-0  add_form_icon">
                <button
                  className="dark-btn h-100 add_form_icon"
                  onClick={(e) => { handleMoreDetailsView(e, "Consigner") }}
                >
                  <i class="bi bi-person-plus-fill"></i>
                </button>
              </div>
            </div>

            {errors?.trip_consigner?.type === "required" && (
              <p className="error_p">Consigner is required</p>
            )}
          </Form.Group>

          <Form.Group className="form-group" controlId="">
            <Form.Label>Consignee <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <div className="d-flex">
              <Form.Select
                style={{
                  borderRadius: "0px",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
                className="add_form_control"
                defaultValue={truckDetails.trip_consignee}
                type="text"
                {...register("trip_consignee", {
                  required: true,
                })}
              >
                <option value="">-Select-</option>
                {loading && <option value="">...Loading</option>}
                {consigneeData?.map((c) => (
                  <option>{c?.consignee_name}</option>
                ))}
              </Form.Select>
              <div className="py-0 px-0 add_form_icon">
                <button
                  className="dark-btn h-100 add_form_icon"
                  onClick={(e) => { handleMoreDetailsView(e, "Consignee") }}
                >
                  <i class="bi bi-person-plus-fill"></i>
                </button>
              </div>
            </div>
            {errors?.trip_consignee?.type === "required" && (
              <p className="error_p">Consignee is required</p>
            )}
          </Form.Group>

          <Form.Group className="form-group" controlId="">
            <Form.Label>Product <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
            <div className="d-flex">
              <Form.Select
                style={{
                  borderRadius: "0px",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
                defaultValue={truckDetails?.trip_product}
                type="text"
                {...register("trip_product", {
                  required: true,
                })}
              >
                <option value="">-Select-</option>
                {productData?.map((product) => {
                  return <option>{product.product_name}</option>;
                })}
              </Form.Select>
              <div className="py-0 px-0 add_form_icon">
                <button
                  className="dark-btn h-100 add_form_icon"
                  onClick={(e) => { handleMoreDetailsView(e, "Product") }}
                >
                  <i class="bi bi-plus "></i>
                </button>
              </div>
            </div>

            {errors?.trip_product?.type === "required" && (
              <p className="error_p">Product is required</p>
            )}
          </Form.Group>
          {/*===========================  Not Required================ */}
          <Form.Group className="form-group" controlId="">
            <Form.Label>LR Number </Form.Label>
            <Form.Control
              defaultValue={truckDetails?.lr_number}
              type="text"
              {...register("lr_number", {
              })}
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="">
            <Form.Label>Invoice Number</Form.Label>
            <Form.Control
              defaultValue={truckDetails?.trip_invoice_number}
              type="text"
              {...register("trip_invoice_number", {

              })}
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="">
            <Form.Label>Invoice Value</Form.Label>
            <div className="d-flex">
              <div className="py-2 px-3 form_icon_icon">
                <i class="bi bi-currency-rupee"></i>
              </div>
              <Form.Control
                style={{
                  borderRadius: "0px",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
                defaultValue={truckDetails?.trip_invoice_value}
                type="text"
                {...register("trip_invoice_value", {

                })}
              />
            </div>

            {errors?.trip_invoice_value?.type === "required" && (
              <p className="error_p">Invoice Value is required</p>
            )}
          </Form.Group>
          {/* ===========================  Not Required================ */}


          <div style={{ clear: "both" }}></div>
          <button type="submit" className="mt-3 btn-next ">
            <h1>
              <i className="bi bi-arrow-right-square-fill"></i>
            </h1>
          </button>

          <div style={{ clear: "both" }}></div>
        </Form>

        {modalHandler && <AddModal modalHandler={modalHandler} addType={addType} modalAction={ModelAction} />}
      </div >
    </>
  );
}

export default TripDetail;
