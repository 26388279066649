import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../config";
import ToastBox from "../../common/Toast/ToastBox";
import "../../common/DeleteModal/DeleteModal.css"
import { useSelector } from "react-redux";

const DeleteFirm = (props) => {
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseType, setResponseType] = useState("");

    useEffect(() => {
        if (responseType) {
            setTimeout(() => {
                setResponseType("");
            }, 3000);
        }
    }, [responseType]);

    async function submitData(firmId) {
        const headers = {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
        };
        setLoading(true);

        try {
            const deleteFirmMutation = {
                query: `
                mutation($user_id: ID!, $firm_id: ID!) {
                    deleteFirm(user_id: $user_id, firm_id: $firm_id) {
                        type
                        message
                        error
                    }
                }`,
                variables: {
                    user_id: +props?.userId,
                    firm_id: +firmId,
                },
            };

            const response = await axios.post(BASE_URL, deleteFirmMutation, { headers });
            console.log("deleteFirmResp", response);

            if (response?.data?.data?.deleteFirm?.type === "success") {
                setTimeout(() => {
                    props.handleClose(false);
                    setLoading(false);
                }, 3000);
            }
        } catch (error) {
            console.error("Error Deleting Firm:", error);
        }
    }



    return (

        <Modal centered show={props?.show} onHide={() => props.handleClose(false)} className="modal-dialog1">
            <Modal.Title className="modal.title1" children>
                Are you sure you would like to delete ?
            </Modal.Title>
            <div className="d-flex modal-btn align-items-center justify-content-between mb-3">
                <button onClick={() => submitData(props?.firmForDelete)}>
                    {loading ? "...Loading" : " Delete"}
                </button>
                <span className="cancel-btn" onClick={() => props.handleClose(false)}>
                    Cancel
                </span>
            </div>
            {responseMessage && responseType && (
                <ToastBox
                    responseMessage={responseMessage}
                    responseType={responseType}
                />
            )}
        </Modal>
    );
};

export default DeleteFirm;
