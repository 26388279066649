import React, { useState } from 'react'
import Uploads from "./Uploads"
import { Tab, Tabs } from 'react-bootstrap'
import ViewDocuments from './ViewDocuments'

const UploadDoc = () => {
    const [reloadKey, setReloadKey] = useState(false);
    const handleReloadTab = () => {
        setReloadKey(!reloadKey);
    };
    return (
        <div className="tabs-sec">
            <Tabs
                onSelect={handleReloadTab}
                defaultActiveKey="uploads"
                id="uncontrolled-tab-example"
                className="mb-4"
                justify
            >
                <Tab eventKey="uploads" title="Document Upload">
                    <Uploads />
                </Tab>
                <Tab eventKey="view" title=" View Documents">
                    <ViewDocuments activeKey={reloadKey} />
                </Tab>
            </Tabs>
        </div>
    )
}

export default UploadDoc