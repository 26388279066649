import React from "react";
import { useForm } from "react-hook-form";
import "../AddFirms/AddFirms.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../../../config";
import { setReload } from "../../../../features/Login";
import CustomizedSnackbars from "../../../common/Popup/Snackbar";
import { capitalizeInput } from "../../../common/FunctionsC/functionsC";
import Loader from "../../../common/Spinner/Loader";
const AddFirms = () => {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const user_id = useSelector((state) => state?.loginDetail?.userDetail.id);
  const [firmError, setFirmError] = useState([]);
  const [show, setShow] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "multipart/form-data",
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    if (show) {
      setTimeout(function () {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `mutation($user_id:ID!,
                $firm_name:String!,
                $firm_email:String!,
                $firm_contact:String!,
                $firm_pancard:String!,
                $firm_gstin:String!,
                $firm_address:String!,
                $firm_licence:Upload!,
                $firm_cin:String
                ){
              addFirm(user_id:$user_id,
                  firm_name:$firm_name,
                  firm_email:$firm_email,
                  firm_contact:$firm_contact,
                  firm_pancard:$firm_pancard,
                  firm_gstin:$firm_gstin,
                  firm_address:$firm_address,
                  firm_licence:$firm_licence
                  firm_cin:$firm_cin
                  ){
              error{
              firm_name
              firm_email
              firm_contact
              firm_pancard
              firm_gstin
              firm_address
              }
              message
              type
              }
              }`,
        variables: {
          user_id: user_id,
          firm_name: data.firm_name,
          firm_email: data.firm_email,
          firm_contact: data.firm_contact,
          firm_pancard: data.firm_pancard,
          firm_gstin: data.firm_gstin,
          firm_address: data.firm_address,
          firm_licence: selectedFile,
          firm_cin: data?.firm_cin,
        },
      })
    );

    formData.append("0", selectedFile, selectedFile.name);

    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.firm_licence"],
      })
    );

    axios
      .post(BASE_URL, formData, { headers })
      .then((response) => {
        setLoading(false);
        setShow(true);
        setMessageType(response.data?.data.addFirm.type);
        if (response.data?.data.addFirm.type == "success") {
          setFirmError(response.data?.data.addFirm.message);
          dispatch(setReload());
          reset();
        }
        if (response.data?.data.addFirm.type == "failed") {
          if (response.data.data.addFirm.error.firm_contact != null)
            setFirmError(response.data.data.addFirm.error.firm_contact[0]);
          else if (response.data.data.addFirm.error.firm_gstin != null)
            setFirmError(response.data.data.addFirm.error.firm_gstin[0]);
          else if (response.data.data.addFirm.error.firm_email != null)
            setFirmError(response.data.data.addFirm.error.firm_email[0]);
          else if (response.data.data.addFirm.error.firm_pancard != null)
            setFirmError(response.data.data.addFirm.error.firm_pancard[0]);
        }
      })
      .catch((error) => {});

    setTimeout(() => {
      dispatch(setReload());
    }, 5000);
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCapture = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setValue("firm_contact", result);
  };

  return (
    <>
      <div className="addfirm_container">
        <h4 className="pt-1">Register your Firm here</h4>
      </div>
      <form
        className="addtruck-form  mt-5 p-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="m-0 p-0">
          <div className="form-group">
            <label for="">
              Firm Licence{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="file"
              className="form-control"
              onChangeCapture={handleFileSelect}
              {...register("firm_licence", {
                required: true,
              })}
            />
            {errors?.firm_licence?.type === "required" && (
              <p className="error_p">Firm name is required</p>
            )}
          </div>

          <div className="form-group">
            <label for="">
              Firm Name{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="text"
              onInput={(e) => capitalizeInput(e)}
              className="form-control"
              id="firm_name"
              {...register("firm_name", {
                required: true,
              })}
            />
            {errors?.firm_name?.type === "required" && (
              <p className="error_p">Firm name is required</p>
            )}
          </div>
          <div className="form-group">
            <label type="text" for="">
              Contact{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="contact"
              className="form-control"
              onChangeCapture={handleCapture}
              id=""
              {...register("firm_contact", {
                required: true,
                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
              })}
            />
            {errors?.firm_contact?.type === "required" && (
              <p className="error_p">Contact number is required</p>
            )}
            {errors?.firm_contact?.type === "pattern" && (
              <p className="error_p">Please entre a valid Contact number</p>
            )}
          </div>
          <div className="form-group">
            <label for="">
              Email{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="email"
              className="form-control"
              id=""
              {...register("firm_email", {
                required: true,
              })}
            />
            {errors?.firm_email?.type === "required" && (
              <p className="error_p">Email is required</p>
            )}
          </div>
          <div className="form-group">
            <label for="">
              Address{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              onInput={(e) => capitalizeInput(e)}
              id=""
              {...register("firm_address", {
                required: true,
              })}
            />
            {errors?.firm_address?.type === "required" && (
              <p className="error_p">Address is required</p>
            )}
          </div>
          <div className="form-group">
            <label for="">
              GST Number{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="text"
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              className="form-control"
              id=""
              {...register("firm_gstin", {
                required: true,
              })}
            />
            {errors?.firm_gstin?.type === "required" && (
              <p className="error_p">GST is required</p>
            )}
          </div>
          <div className="form-group">
            <label for="">
              Pan Card Number{" "}
              <sup>
                <i class="bi bi-asterisk my-icon"></i>
              </sup>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toUpperCase())
              }
              id=""
              {...register("firm_pancard", {
                required: true,
                minLength: 10,
                maxLength: 10,
                pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
              })}
            />
            {errors?.firm_pancard?.type === "pattern" && (
              <p className="error_p">Please entre valid PAN number </p>
            )}
            {errors?.firm_pancard?.type === "maxLength" && (
              <p className="error_p">PAN number cannot exceed 10 characters</p>
            )}
            {errors?.firm_pancard?.type === "minLength" && (
              <p className="error_p">
                PAN number cannot be less than 10 characters
              </p>
            )}
            {errors?.firm_pancard?.type === "required" && (
              <p className="error_p">PAN card number is required</p>
            )}
          </div>
          <div className="form-group">
            <label for="">CIN </label>
            <input
              type="text"
              className="form-control"
              id=""
              {...register("firm_cin", {})}
            />
          </div>

          <button
            type="submit"
            className="btn dark-btn mx-auto d-block mt-5 mb-3"
          >
            {loading ? (
              <>
                <Loader /> Submitting...
              </>
            ) : (
              <>Submit</>
            )}
          </button>

          {show && (
            <CustomizedSnackbars
              show={show}
              message={firmError}
              messageType={messageType}
            />
          )}
        </div>
      </form>
    </>
  );
};
export default AddFirms;
