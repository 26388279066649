import React, { useEffect, useMemo, useState } from 'react'
import { City } from "country-state-city";
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { setSelectedDestination } from '../../../features/FilterComponents';

const DestinationFilter = () => {
  const [destination, setDestination] = useState();
  const dispatch = useDispatch();
  const cities = City.getCitiesOfCountry("IN");
  const CitiesOption = cities?.map((c) => {
    return { value: c.name, label: c.name }
  })

  useEffect(() => {
    dispatch(setSelectedDestination(destination))
  }, [destination])

  const handleDestinationSelect = (e) => {
    setDestination(e?.value)
  }
  return <>
    <CreatableSelect
      required
      className="basic-single w-100 pe-2"
      classNamePrefix="select"
      isClearable={true}
      isSearchable={true}
      onChange={handleDestinationSelect}
      options={CitiesOption}
    />
  </>

}

export default DestinationFilter