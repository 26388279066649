import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BACKEND_URL, BASE_URL } from '../../../config'
import Loader from "react-js-loader";

const DocModal = ({ show, firm_id, truck_id, docType, closeModal }) => {
    const [url, setUrl] = useState()
    const [loading, setLoading] = useState(false);


    // console.log("propssss", show, firm_id, truck_id, docType)
    console.log("path", `${BACKEND_URL}/${url}`)

    useEffect(() => {
        setLoading(true)
        const TruckDocumentQuery = {
            query: `query(
                         $firm_id:ID!,
                         $truck_id:ID!,
                         $document_type:String!
                         $isExpired:Boolean!
                         ){
                            truckDocument(
                                firm_id:$firm_id,
                                truck_id:$truck_id,
                                document_type:$document_type
                                isExpired:$isExpired
                            ){
                                truck_id
                                firm_id
                                document_type
                                document_path
                                document_number
                                issue_date
                                expiry_date
                            }
                         }`,
            variables: {
                firm_id: firm_id,
                truck_id: truck_id,
                document_type: docType,
                isExpired: false
            }

        }
        axios.post(BASE_URL, TruckDocumentQuery)
            .then((response) => {
                setLoading(false)
                console.log("query doc response", response)
                setUrl(response.data.data.truckDocument.document_path);
            })
            .catch((error) => {
                console.log("query error doc", error)
            })

    }, [firm_id, truck_id, docType])

    return (
        <>
            <Modal
                show={show}
                fullscreen={true}
                scrollable={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => closeModal(false)}
            >
                <Modal.Header
                    style={{ float: "right" }}
                >
                </Modal.Header>
                <Modal.Body className='doc_modal'>
                    {loading
                        ?
                        <Loader type="spinner-default" bgColor="#f4801f" color="#f4801f" size={80} />
                        :
                        (url?.endsWith(".pdf")
                            ?
                            <embed className='w-100 h-100' src={url != undefined ? (`${BACKEND_URL}/${url}`) : "/no-data.png"} />
                            :
                            <div className="d-flex aline-item-center pt-2 no_data-img">
                                <img src={url != undefined ? (`${BACKEND_URL}/${url}`) : "/no_data.png"}></img>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </>

    )
}

export default DocModal