import axios from "axios";
import React, { useEffect, useState } from "react";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import TanStackTable from "../../common/Table/TanStackTable";
import { createColumnHelper } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import InvoiceModal from "../Trips/InvoiceModal";
import InvoiceViewModal from "./InvoiceViewModal";
import Select from "react-select";
import { City } from "country-state-city";
import ConsignerFilter from "../Filters/ConsignerFilter";
import ConsigneeFilter from "../Filters/ConsigneeFilter";
import InvoiceNumberFilter from "../Filters/InvoiceNumberFilter";
import LrNumberFilter from "../Filters/LrNumberFilter";
import SourceFilter from "../Filters/SourceFilter";
import DestinationFilter from "../Filters/DestinationFilter";
import DateFilter from "../Filters/DateFilter";
import { BASE_URL } from "../../../config";
import { setLogout } from "../../../features/Login";

const InvoiceData = () => {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  const selectedConsigner = useSelector(
    (state) => state?.filterComponents?.selectedConsigner
  );
  const selectedConsignee = useSelector(
    (state) => state?.filterComponents?.selectedConsignee
  );
  const selectedInvoiceNumber = useSelector(
    (state) => state?.filterComponents?.selectedInvoiceNumber
  );
  const selectedSource = useSelector(
    (state) => state?.filterComponents?.selectedSource
  );
  const selectedDestination = useSelector(
    (state) => state?.filterComponents?.selectedDestination
  );
  const selectedDate = useSelector(
    (state) => state?.filterComponents?.selectedDate
  );
  const selectedLrNumber = useSelector(
    (state) => state?.filterComponents?.selectedLrNumber
  );
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState();
  const [modalHandler, setModelHandler] = useState(false);
  const [tripId, setTripId] = useState();
  const [modalType, setModalType] = useState();
  const [loading, setLoading] = useState(true);
  const [paymentId, setPaymentId] = useState();
  const columnHelper = createColumnHelper();
  const invoiceOptions = [
    { value: "pending", label: "Pending" },
    { value: "closed", label: "Closed" },
    { value: "Settle", label: "Settle" },
    { value: "Shortage", label: "Shortage" },

  ];

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
  };
  const updateType = "update_invoice";
  const dispatch = useDispatch();

  useEffect(() => {
    // === query to get All Invoices ========
    const query1 = {
      query: `query($firm_id:ID!,
                    $trip_date:String,
                    $invoice_status:String,
                    $lr_number:String,
                    $trip_consigner:String,
                    $trip_consignee:String,
                    $trip_source:String,
                    $trip_destination:String,
                    $trip_product:String,
                    $trip_invoice_number:String
                    ){
        firmInvoice(firm_id:$firm_id,
                    trip_date:$trip_date,
                    invoice_status:$invoice_status,
                    lr_number:$lr_number,
                    trip_consigner:$trip_consigner,
                    trip_consignee:$trip_consignee,
                    trip_source:$trip_source,
                    trip_destination:$trip_destination,
                    trip_product:$trip_product,
                    trip_invoice_number:$trip_invoice_number,         
                    ){
            trip_id
            package_quantity
            total_weight
            rate
            freight
            total_amount
            remaining
            lr_number
            trip_invoice_id
            invoice_status 
            shortage_amount
            truckTrip{
              trip_date
              truck{
                truck_number
              }
            }
         
        }
      }`,
      variables: {
        firm_id: firm_id,
        trip_date: selectedDate,
        invoice_status: selectedInvoiceStatus,
        lr_number: selectedLrNumber,
        trip_consigner: selectedConsigner,
        trip_consignee: selectedConsignee,
        trip_source: selectedSource,
        trip_destination: selectedDestination,
        trip_invoice_number: selectedInvoiceNumber,
      },
    };

    axios
      .post(BASE_URL, query1, { headers })
      .then((response) => {
        if (response.data?.errors?.[0].message == "Unauthenticated.") {
          dispatch(setLogout());
        } else {
          setInvoiceData((response.data?.data.firmInvoice).reverse());
          setLoading(false);
        }
      })
      .catch((error) => { });
  }, [
    firm_id,
    modalHandler,
    selectedConsigner,
    selectedInvoiceStatus,
    selectedLrNumber,
    selectedInvoiceNumber,
    selectedConsignee,
    selectedConsigner,
    selectedSource,
    selectedDestination,
    selectedDate,
  ]);

  const columns = [
    columnHelper.accessor((row) => row?.truckTrip.trip_date, {
      id: "trip_date",
      cell: (info) => info.getValue().split("-").reverse().join("-"),
      header: () => <span>Date</span>,
    }),
    // columnHelper.accessor("trip_invoice_id", {
    //   header: "Invoice ID",
    // }),
    columnHelper.accessor("trip_id", {

    }),
    columnHelper.accessor((row) => row?.truckTrip.truck.truck_number, {
      id: "truck_number",
      cell: (info) => info.getValue(),
      header: () => <span>Truck Number</span>,
    }),
    columnHelper.accessor("lr_number", {
      header: 'LR Number',
      cell: info => {
        let data = info.getValue()
        return data != null ? data : "-"
      }

    }),
    columnHelper.accessor("invoice_status", {
      header: "Invoice Status",
    }),
    columnHelper.accessor("package_quantity", {
      header: "Package Qty.",
      cell: info => {
        let data = info.getValue()
        return data !== null ? data : "-"
      },
    }),

    columnHelper.accessor("freight", {
      header: "Freight",
      cell: (info) => "₹ " + info.getValue(),
    }),
    columnHelper.accessor("total_amount", {
      header: "Total Amount",
      cell: (info) => "₹ " + info.getValue(),
    }),

    columnHelper.accessor("remaining", {
      header: "Remaining",
      cell: (info) => "₹ " + info.getValue(),
    }),

    columnHelper.accessor("trip_invoice_id", {
      header: "View",
      cell: (props) => (
        <button
          type="button"
          key1={props?.row?._valuesCache?.trip_id}
          value={props.getValue()}
          className="dark-btn rounded m-auto  bi bi-eye"
          onClick={handleInvoiceView}
        ></button>
      ),
    }),
    columnHelper.accessor("invoice_status", {
      header: "Update",
      cell: (props) => {
        return props.getValue() == "pending" &&
          props?.row?._valuesCache?.remaining != 0 ? (
          <button
            type="button"
            key={props?.row?._valuesCache?.trip_id}
            value={props?.row?._valuesCache?.trip_id}
            className="dark-btn rounded m-auto  bi bi-pencil-square"
            onClick={handleInvoiceUpdate}
          >
            {" "}
          </button>
        ) : (
          <p>N/A</p>
        );
      },
    }),
  ];
  // ===== Modal Handlers ========

  const handleInvoiceView = (e) => {
    setTripId(e?.target.getAttribute("key1"));
    setModelHandler(true);
    setPaymentId(e?.target.value);
    setModalType("invoice_show_modal");
  };
  const handleInvoiceUpdate = (e) => {
    setModelHandler(true);
    setTripId(e?.target.value);
    setModalType("invoice_update_modal");
  };

  function ModalAction(item) {
    setModelHandler(item);
  }
  // ======= Select Handlers ==========

  const handleInvoiceStatusSelect = (e) => {
    setSelectedInvoiceStatus(e?.value);
  };


  return (
    <>
      <div
        className="mb-4"
        style={{
          backgroundColor: "#10233c",
          color: "white",
          height: "40px",
          textAlign: "center",
        }}
      >
        <h4 className="pt-1">Trip Invoices</h4>
      </div>
      <div className="d-flex justify-content-evenly filter-overlay" style={{ zIndex: "999", position: "relative" }}>
        <div className="d-flex flex-column w-25 pe-2 mt-3">
          <label>Date</label>
          <DateFilter />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>Status</label>
          <Select
            className="basic-single w-100 pe-2"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            onChange={handleInvoiceStatusSelect}
            options={invoiceOptions}
          />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>LR Number</label>
          <LrNumberFilter />
        </div>
        <div className="d-flex flex-column w-25 mt-3">
          <label>Invoice Number</label>
          <InvoiceNumberFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Consigner</label>
          <ConsignerFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Consignee</label>
          <ConsigneeFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Source</label>
          <SourceFilter />
        </div>

        <div className="d-flex flex-column w-25 mt-3">
          <label>Destination</label>
          <DestinationFilter />
        </div>
      </div>
      <hr />

      {loading ? (
        <SimpleBackdrop />
      ) : invoiceData?.length > 0 ? (
        <>
          <TanStackTable data={invoiceData} columns={columns} />
        </>
      ) : (
        <div className="d-flex aline-item-center pt-2 no_data-img">
          <img src="/no_data.png"></img>
        </div>
      )}

      {modalHandler && modalType == "invoice_update_modal" && (
        <InvoiceModal
          modalHandler={modalHandler}
          tripId={tripId}
          modalAction={ModalAction}
          updateType={updateType}
        />
      )}
      {modalHandler && modalType == "invoice_show_modal" && (
        <InvoiceViewModal
          modalHandler={modalHandler}
          paymentId={paymentId}
          tripId={tripId}
          modalAction={ModalAction}
        />
      )}
    </>
  );
};

export default InvoiceData;
