
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import CustomizedSnackbars from '../../common/Popup/Snackbar';
import Loader from '../../common/Spinner/Loader';

const AddVendor = () => {
    const [responseMessage, setResponseMessage] = useState();
    const [show, setShow] = useState();
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false)
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };

    const onSubmit = (data) => {
        setLoading(true)
        const query = {
            query: `mutation($firm_id:ID!,$vendor_name:String!){
              addVendor(firm_id:$firm_id,vendor_name:$vendor_name)
              {
                message
                type
                error{
                  firm_id
                  vendor_name
                }
              }
      }`,
            variables: {
                firm_id: firm_id,
                vendor_name: data?.vendor_name
            }
        }

        axios.post(BASE_URL, query, { headers })
            .then((response) => {
                if (response?.data?.data.addVendor.type == "success") {
                    setLoading(false)
                    setShow(true);
                    setResponseMessage(response.data?.data.addVendor.message);
                    setMessageType(response.data?.data.addVendor.type);
                    reset();
                }
            })
            .catch((error) => {
            })

    }

    useEffect(() => {
        if (show) {
            setTimeout(function () {
                setShow(false);
            }, 5000);
        }
    }, [show]);

    return (

        <Form className='' onSubmit={handleSubmit(onSubmit)}>
            {/* <Form.Group className="form-group w-100" controlId=""> */}
            <Form.Label>Vendor <sup><i class="bi bi-asterisk my-icon"></i></sup> </Form.Label>
            <div className="d-flex w-75 ">
                <input
                    style={{
                        borderRadius: "0px",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        width: "100%"
                    }}
                    type="text"
                    className='form-control_icon '
                    {...register("vendor_name", {
                        required: true,
                    })}
                />
                {/* </input> */}
                <div className="py-0 px-0  add_form_icon">
                    <button className="dark-btn h-100 add_form_icon" type='submit'>
                        {loading ? <Loader /> :
                            <i className="bi bi-plus "></i>
                        }
                    </button>
                </div>
            </div>
            {errors?.vendor_name?.type === "required" && (
                <p className="error_p">Vendor  is required</p>
            )}
            {/* </Form.Group> */}

            <div style={{ clear: "both" }}></div>
            {show && (
                <CustomizedSnackbars
                    show={show}
                    message={responseMessage}
                    messageType={messageType}
                />
            )}
        </Form>
    );
};

export default AddVendor;