
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import CustomizedSnackbars from '../../common/Popup/Snackbar';
import Loader from '../../common/Spinner/Loader';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import "./Uploads.css"
import AddVendor from './AddVendor';
const Uploads = () => {

    const navigate = useNavigate()
    const currentDate = moment();
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const firm_Id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const user_id = useSelector((state) => state?.loginDetail?.userDetail?.id);
    const [responseMessage, setResponseMessage] = useState();
    const [show, setShow] = useState();
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState()
    const [vendorList, setVendorList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)

    const formattedDate = currentDate.format('YYYY-MM-DD');
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "all",
    });

    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        setLoading(true)
        const vendorQuery = {
            query: `query($firm_id:ID!){
                vendor(firm_id:$firm_id){
                    firm_id
                    vendor_id
                    vendor_name
                }
            }`,
            variables: {
                firm_id: firm_Id
            }
        }

        axios.post(BASE_URL, vendorQuery, { headers })
            .then((response) => {
                setLoading(false)
                setVendorList(response?.data?.data?.vendor)
            })
            .catch((error) => {
            })
    }, [firm_Id, isModalOpen])

    useEffect(() => {
        if (show) {
            setTimeout(function () {
                setShow(false);
            }, 5000);
        }
    }, [show]);

    const onSubmit = (data) => {
        setBtnLoader(true);
        const headers = {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "multipart/form-data",
        };
        const formData = new FormData();
        formData.append(
            "operations",
            JSON.stringify({
                query: `mutation(
              $user_id: ID!
              $firm_id: ID!
              $doc_uploader: String
              $doc_upload_date: Date!
              $doc_type: String!
              $doc_vendor: String!
              $doc_payment_type: String!
              $doc_file: Upload!
            ){
              addDocument(
                user_id: $user_id
                firm_id: $firm_id
                doc_uploader: $doc_uploader
                doc_upload_date: $doc_upload_date
                doc_type: $doc_type
                doc_vendor: $doc_vendor
                doc_payment_type: $doc_payment_type
                doc_file: $doc_file
              ){
                type
                message
                error{
                    user_id
                    firm_id
                    doc_upload_date
                    doc_type
                    doc_vendor
                    doc_payment_type
                    doc_file
                }
              }
            }`,
                variables: {
                    user_id: user_id,
                    firm_id: firm_Id,
                    doc_upload_date: data?.doc_upload_date,
                    doc_uploader: data?.doc_uploader,
                    doc_type: data?.doc_type,
                    doc_vendor: data?.doc_vendor,
                    doc_payment_type: data?.doc_payment_type,
                    doc_file: selectedFile,
                },
            })
        );
        formData.append("0", selectedFile, selectedFile.name);
        formData.append(
            "map",
            JSON.stringify({
                0: ["variables.doc_file"],
            })
        );

        axios.post(BASE_URL, formData, { headers })
            .then((response) => {
                setBtnLoader(false)
                setMessageType(response?.data?.data?.addDocument.type)
                if (response?.data?.data?.addDocument.type === "success") {
                    setResponseMessage(response?.data?.data?.addDocument.message)
                    reset()
                    setSelectedFile()
                }
                else {
                    setResponseMessage(response?.data?.data?.addDocument.error)
                }
                setShow(true)
            })
            .catch((error) => {
            })
    }



    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const takePicture = () => {

        if (window.cordova) {
            document.addEventListener("deviceready", testPic, false);
        }
    }

    const testPic = () => {
        if (window.navigator && window.navigator.camera) {
            window.navigator.camera.getPicture(
                (imageData) => {
                    onSuccess(imageData);
                },
                onFail, {
                quality: 50,
                destinationType: window.Camera.DestinationType.DATA_URL,
            });
        } else {
            alert('Camera API not available');
        }
    };

    const onSuccess = (imageData) => {
        const fileInput = document.getElementById('fileInput');
        fileInput.value = '';
        var file = b64toBlob(imageData, "image/jpeg");
        setSelectedFile(file)
    }

    const onFail = message => {

    };
    useEffect(() => {
        console.log("selected_file", selectedFile);
    }, [selectedFile])

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }


        const formattedDate1 = currentDate.format('YYYY-MM-DDTHH:mm:ss');
        const datePart = formattedDate1.substring(3, 13).replace(/-/g, '');
        const timePart = formattedDate1.substring(14).replace(/:/g, '');
        const formattedString = `IMG_${datePart}_${timePart}`;
        console.log(formattedString)
        return new File(byteArrays, `${formattedString}`, { type: contentType });
    }

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)} className="upload-form">
                    <div className="form-group-upload">
                        <label for="">
                            Date <sup>
                                <i class="bi bi-asterisk my-icon"></i>
                            </sup>
                        </label>
                        <input
                            type="date"
                            defaultValue={formattedDate}
                            className="form-control"
                            id=""
                            {...register("doc_upload_date", {
                                required: true,
                            })}
                        />
                        {errors?.doc_upload_date?.type === "required" && (
                            <p className="error_p mb-3">Email is required</p>
                        )}
                    </div>
                    <div className="form-group-upload">
                        <label for="">
                            Uploader
                        </label>
                        <input
                            type="text"
                            className="form-control"

                            placeholder='Enter Your Name'
                            id=""
                            {...register("doc_uploader", {
                            })}
                        />
                    </div>
                    <div className="form-group-upload " >

                        <div className=' d-flex'>
                            <label class="form-check-label" for="flexRadioDisabled">
                                Bill Type  {" "}<sup>
                                    <i class="bi bi-asterisk my-icon"></i>
                                </sup> {"    "}:
                            </label> {" "}
                            <div class="form-check ms-3">
                                <input class="form-check-input " value="gst" type="radio" name="flexRadioDisabled" id="flexRadioDisabled"
                                    {...register("doc_type", { required: true })} />
                                <label class="form-check-label" for="flexRadioDisabled">
                                    GST
                                </label>
                            </div>
                            <div class="form-check ms-3">
                                <input class="form-check-input" type="radio"
                                    value="non-gst" name="flexRadioDisabled" id="flexRadioCheckedDisabled"
                                    {...register("doc_type", { required: true })} />
                                <label class="form-check-label" for="flexRadioCheckedDisabled">
                                    Non-GST
                                </label>
                            </div>
                        </div>
                        {errors?.doc_type?.type === "required" && (
                            <p className="error_p pb-5">Bill Type is required</p>
                        )}
                    </div>
                    <div className="form-group-upload">
                        <label for="">
                            Vendor  <sup>
                                <i class="bi bi-asterisk my-icon"></i>
                            </sup>
                        </label>
                        <div className="d-flex">
                            <select
                                style={{
                                    borderRadius: "0px",
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                }}
                                className="add_form_control"

                                type="text"
                                {...register("doc_vendor", {
                                    required: true,
                                })}
                            >

                                <option value="">-Select-</option>
                                {
                                    loading
                                        ?
                                        <option value="">...Loading</option>
                                        :
                                        vendorList?.map((el) =>
                                            <option value={el.vendor_id} key={el.vendor_id}>{el.vendor_name}</option>
                                        )
                                }
                            </select>
                            <div className="py-0 px-0 add_form_icon">
                                <button
                                    className="dark-btn h-100 add_form_icon"
                                    onClick={(e) => setIsModalOpen(true)}
                                >
                                    <i class="bi bi-person-plus-fill"></i>
                                </button>
                            </div>
                        </div>
                        {errors?.doc_vendor?.type === "required" && (
                            <p className="error_p">Vendor is required</p>
                        )}
                    </div>
                    <div>
                        <div className="form-group-upload">
                            <div className='d-flex'>
                                <label class="form-check-label" for="flexRadioDisabled">
                                    Payment Type  <sup>
                                        <i class="bi bi-asterisk my-icon"></i>
                                    </sup> :
                                </label>
                                <div class="form-check  ms-3">
                                    <input class="form-check-input " value="cash" type="radio" name="flexRadio" id="flexRadio"
                                        {...register("doc_payment_type", { required: true })} />
                                    <label class="form-check-label" for="flexRadio" >
                                        Cash
                                    </label>
                                </div>
                                <div class="form-check  ms-3">
                                    <input class="form-check-input" type="radio"
                                        value="credit" name="flexRadio" id="flexRadioChecked"
                                        {...register("doc_payment_type", { required: true })} />
                                    <label class="form-check-label" for="flexRadioChecked">
                                        Credit
                                    </label>
                                </div>

                            </div>

                            {errors?.doc_payment_type?.type === "required" && (
                                <p className="error_p">Payment Type is required</p>
                            )}
                        </div>
                    </div>
                    <div className="form-group-upload">
                        <label for="">
                            Upload Document
                            <sup>
                                <i class="bi bi-asterisk my-icon"></i>
                            </sup>
                        </label>
                        {
                            window.cordova
                                ?
                                <input
                                    type="file"
                                    onChangeCapture={handleFileSelect}
                                    className="form-control"
                                    id="fileInput"
                                    {...register("doc_file", {

                                    })}

                                />
                                :
                                <input
                                    type="file"
                                    onChangeCapture={handleFileSelect}
                                    className="form-control"
                                    id="fileInput"
                                    {...register("doc_file", {
                                        required: true,
                                    })}

                                />
                        }


                    </div>

                    {
                        window.cordova &&
                        <>
                            <p>OR</p>
                            <div className='fileInfoDiv form-group-upload'>
                                <p onClick={takePicture} class="bi bi-camera-fill"> </p>
                                <input type="text" id="fileInfo" placeholder='No Image Captured' value={selectedFile?.name} readOnly />

                            </div>
                        </>
                    }
                    {errors?.doc_file?.type === "required" && (
                        <p className="error_p_tag">File is required</p>
                    )}

                    <button type='submit' className='upload-dark-btn rounded'>
                        {btnLoader ? <><Loader /> Uploading</> : "Upload"}
                    </button>
                </form >
            </div>

            <Modal
                show={isModalOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={closeModal}
            >
                <Modal.Header
                    closeButton
                >
                </Modal.Header>
                <Modal.Body className='my-4 mb-5'>
                    <AddVendor />
                </Modal.Body>
            </Modal >

            {show && (
                <CustomizedSnackbars
                    show={show}
                    message={responseMessage}
                    messageType={messageType}
                />
            )}
        </>
    );
};

export default Uploads;