import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const firmId = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
  return (
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
          Menu
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div className="sidebar">

          <ul>
            <li data-bs-dismiss="offcanvas">
              <NavLink to="/">
                <i className="bi bi-house"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li data-bs-dismiss="offcanvas">
              <NavLink to="/firms">
                <i className="bi bi-buildings"></i>
                <span>Firms</span>
              </NavLink>
            </li>
            <ul className={firmId ? "" : "disabled-list"}>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/drivers">
                  <i class="bi bi-person-bounding-box"></i>
                  <span>Drivers</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/trucks">
                  <i className="bi bi-truck"></i>
                  <span>Trucks</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/loadtruck">
                  <i class="bi bi-truck-flatbed"></i>
                  <span>Start Trip</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/trips">
                  <i className="bi bi-rocket-takeoff"></i>
                  <span>Trips</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/add">
                  <i class="bi bi-plus-square"></i>
                  <span>Add</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/expense">
                  <i className="bi bi-currency-rupee"></i>
                  <span>Expenses</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/invoice">
                  <i class="bi bi-file-earmark-text"></i>
                  <span>Invoices</span>
                </NavLink>
              </li>
              <li data-bs-dismiss="offcanvas">
                <NavLink to="/uploads">
                  <i class="bi bi-cloud-upload"></i>
                  <span>Uploads</span>
                </NavLink>
              </li>
              {/* <li data-bs-dismiss="offcanvas">
                <NavLink to="/buyplans">
                  <i class="bi bi-wallet"></i>
                  <span>BuyPlans</span>
                </NavLink>
              </li> */}


            </ul>
            <li data-bs-dismiss="offcanvas">
              <NavLink to="/faqs">
                <i class="bi bi-patch-question"></i>
                <span>Help</span>
              </NavLink>
            </li>
          </ul>


        </div>
      </div>
    </div>
  );
};
class App extends React.Component { }
export default Sidebar;
