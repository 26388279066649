import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Container, Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Profile.css";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { BASE_URL } from '../../../config';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { setUserDetails } from "../../../features/Login";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import Loader from "../../common/Spinner/Loader";
export default function Profile() {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const userDetails = useSelector((state) => state?.loginDetail?.userDetail);
  const userDetail = useSelector((state) => state?.loginDetail?.userDetail);

  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [messageType, setMessageType] = useState()
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState()
  const [show, setShow] = useState(false)
  const dispatch = useDispatch();

  if (userDetail == "") {
    setLoading(true);
  }
  const headers = {
    'Authorization': `Bearer ${bearerToken}`,
    'Content-Type': 'application/json'
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "all"
  });

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      setModalOpen(false);

    }, 3000)
  }, [messageType])


  const handleProfileUpdate = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onSubmit = (data) => {
    setLoadingBtn(true)
    const profileDataQuery2 = {
      query: `mutation ($user_id:ID!, $name:String!, $mobile:String!){
        updateProfile(
            user_id:$user_id,
            name:$name,
            mobile:$mobile
            ){
              type
              message
              userDetail{
                id 
                name
                email
                mobile
                created_at
                updated_at
                email_verified_at
              }
              error {
                user_id
                name
                mobile
              }
            }
        }`,
      variables: {
        user_id: +userDetails?.id,
        name: data?.name,
        mobile: data?.mobile
      }
    }
    axios.post(BASE_URL, profileDataQuery2, { headers })
      .then((response) => {
        setLoadingBtn(false)
        setShow(true)
        setMessageType(response?.data?.data?.updateProfile?.type)
        setMessage(response?.data?.data?.updateProfile?.message)
        if (response?.data?.data?.updateProfile?.type == "success") {
          dispatch(setUserDetails(response?.data?.data?.updateProfile?.userDetail))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };



  return (
    <>
      {loading ? (
        <SimpleBackdrop />
      ) : (
        <div>
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={8} lg={50} xs={12}>
                <div className="mb-3 mt-md-3 profile-box">
                  <div className="m-auto">
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        margin: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <Tooltip title="Edit" placement="right-end" arrow>
                            <SmallAvatar
                              alt="Remy Sharp"
                              src="/pngegg.png"
                              onClick={handleProfileUpdate}
                            />
                          </Tooltip>
                        }
                      >
                        <LargeAvatar alt="Travis Howard" src="/user.png" />
                      </Badge>
                      {isModalOpen && (
                        <div className="modal">
                          <div className="modal-content">
                            <span className="close" onClick={closeModal}>&times;</span>




                            <Modal
                              show={isModalOpen}
                              size="sm"
                              scrollable={true}
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header
                                className='mb-4'
                                style={{
                                  backgroundColor: "#10233c",
                                  color: "white",
                                  height: "40px",
                                  textAlign: "center",
                                }}
                              >
                                <h4 className=" my-0 m-auto">Profile Update</h4>
                              </Modal.Header>

                              <Modal.Body>
                                <Form onSubmit={handleSubmit(onSubmit)} className="edit-form">

                                  <Form.Group className="form-group-edit">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control defaultValue={userDetails?.email || ''} className="form-control"
                                      type="email"
                                      // {...register("email", {

                                      // })}
                                      disabled
                                    />
                                  </Form.Group>

                                  <Form.Group className="form-group-edit">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control defaultValue={userDetails?.name || ''}
                                      className="form-control"
                                      type="text"
                                      {...register("name", {

                                      })}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group-edit">
                                    <Form.Label>Mobile No.</Form.Label>
                                    <Form.Control defaultValue={userDetails?.mobile || ''}
                                      className="form-control"
                                      type="text"
                                      {...register("mobile", {

                                      })}
                                    />
                                  </Form.Group>

                                  <Button className="close-btn " onClick={() => closeModal(true)} >
                                    Close
                                  </Button>
                                  <Button type="submit" className="update-btn">
                                    {loadingBtn ? (
                                      <>
                                        <Loader /> Updating...
                                      </>
                                    ) : (
                                      <>update</>
                                    )}
                                  </Button>

                                </Form>
                              </Modal.Body>
                            </Modal>

                          </div>
                        </div>
                      )}
                    </Stack>
                  </div>
                  <hr />
                  <div className="justify-content-center align-items-center">
                    <Col>
                      <i className="bi bi-person-check red-text"></i>{" "}
                      <span className="p-2">
                        {" "}
                        Name &nbsp;:&nbsp; {userDetail.name}
                      </span>
                    </Col>

                    <hr />

                    <Col>
                      {" "}
                      <i className="bi bi-envelope-at red-text"></i>
                      <span className="p-2">
                        E-mail &nbsp;:&nbsp; {userDetail.email}
                      </span>{" "}
                    </Col>

                    <hr />

                    <Col>
                      <i className="bi bi-phone red-text"></i>
                      <span className="p-2">
                        Mobile &nbsp;:&nbsp; {userDetail.mobile}
                      </span>
                    </Col>

                    <hr />

                    <Col>
                      <i className="bi bi-lock red-text "></i>
                      <span className="p-2">
                        Need to change password ? {" "}
                        <Link to="/changepassword" className="red-text">
                          Change Password
                        </Link>
                      </span>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container >
        </div >

      )
      }

      {show && (
        <CustomizedSnackbars
          show={show}
          message={message}
          messageType={messageType}
        />
      )}
    </>
  );
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
  border: `5px solid ${theme.palette.background.paper}`,
  cursor: "pointer",
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: 130, // double the width
  height: 130, // double the height
}));
