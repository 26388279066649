import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "../../common/Tabs/Tabs.css";
import ActiveTrips from './ActiveTrips';
import ClosedTrips from './ClosedTrips';
import { useState } from 'react';

function Trips() {
  const [reloadKey, setReloadKey] = useState(false);
  const handleReloadTab = () => {
    setReloadKey(!reloadKey);
  };
  return (<>
    <div className='tabs-sec'>
      <Tabs
        onSelect={handleReloadTab}
        defaultActiveKey="activetrip"
        id="uncontrolled-tab-example"
        className="mb-4"
        justify

      >
        <Tab eventKey="activetrip" title="ACTIVE TRIPS">
          <ActiveTrips />
        </Tab>
        <Tab eventKey="loadtruck" title="CLOSED TRIPS" >
          <ClosedTrips activeKey={reloadKey} />
        </Tab>
      </Tabs>
    </div>
  </>

  )
}

export default Trips;



