import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { setSelectedConsigner } from '../../../features/FilterComponents';
import { BASE_URL } from '../../../config';

const ConsignerFilter = () => {
    const firm_id = useSelector((state) => state?.loginDetail?.firmDetails?.firm_id);
    const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
    const [tripConsigner, setTripConsigner] = useState();
    const [consigner, setConsigner] = useState();

    const dispatch = useDispatch();
    const headers = {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        dispatch(setSelectedConsigner(consigner))
    }, [consigner])

    useEffect(() => {
        const consignerQuery = {
            query: `query($firm_id:ID,$limit:Int)
        {
          consigner(firm_id:$firm_id,limit:$limit)
          {
            consigner_name
          }
        }`,
            variables: {
                firm_id: firm_id,
                limit: 3
            }
        }
        axios.post(BASE_URL, consignerQuery, { headers })
            .then(response => {
                setTripConsigner(response?.data?.data.consigner.map((c) => {
                    return { value: c.consigner_name, label: c.consigner_name }
                }))

            })
            .catch(error => {
            })

    }, [firm_id])

    const filterConsigner = (inputValue) => {
        const query = {
            query: `query($firm_id:ID,$input:String){
            firmConsigner(firm_id:$firm_id,input:$input)
            {
              consigner_name
            }
          }`,
            variables: {
                firm_id: firm_id,
                input: inputValue
            }
        }

        return axios.post(BASE_URL, query, { headers })
            .then((response) => {
                return response.data.data.firmConsigner.map((item) => {
                    return { value: item?.consigner_name, label: item?.consigner_name }
                })
            })
            .catch((error) => {
            })

    }
    const handleConsignerSelect = (e) => {
        setConsigner(e?.value)
    }
    const queryOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterConsigner(inputValue));
            }, 1000);
        });

    return (<>
        <AsyncSelect
            className="basic-single w-100 pe-2"
            cacheOptions
            isClearable
            defaultOptions={tripConsigner}
            loadOptions={queryOptions}
            onChange={handleConsignerSelect}
        />
    </>
    )
}

export default ConsignerFilter