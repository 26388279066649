import React from 'react'
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { setDecrementStep, setIncrementStep } from '../../../../features/DriverForm';

function LicenceDetails() {
  const dispatch = useDispatch();
  const licenceDetails = useSelector((state) => state?.driverFormReducer?.licenceDetails);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    dispatch(setIncrementStep(data));
  }
  const BackStep = () => {
    dispatch(setDecrementStep());
  }
  return (
    <div className='mt-5'>
      <Form className='adddriver-form p-3' onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="form-group">
          <Form.Label>Driving Licence <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control type='file'
            {...register("driver_uploaded_licence", {
              required: true,
            })}
          />
          {errors?.driver_uploaded_licence?.type === "required" && <p className="error_p">Licence Photo is required</p>}

        </Form.Group>

        <Form.Group className="form-group" controlId="">
          <Form.Label>DL No. <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={licenceDetails.driver_licence_no} type="text"
            onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
            {...register("driver_licence_no", {
              required: true,
              minLength: 15,
              validate: {
                upper: (value) => /[A-Z]/.test(value),
                number: (value) => /[0-9]/.test(value),
              },
            })} />
          {errors?.driver_licence_no?.type === "required" && <p className="error_p">licence number is required</p>}
          {errors?.driver_licence_no?.type === "upper" && <p className="error_p">DL no. should contain Alphabte character also  </p>}
          {errors?.driver_licence_no?.type === "number" && <p className="error_p">DL no. should contain Number also </p>}
          {errors?.driver_licence_no?.type === "minLength" && <p className="error_p">licence number should be more than or equal to 15 numbers</p>}

        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Class <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Select defaultValue={licenceDetails.driver_class} aria-label="Default select example"
            {...register("driver_class", {
              required: true,
            })}>
            <option value="">- Select -</option>
            <option value="heavy">Heavy</option>
            <option value="light">Light</option>
          </Form.Select>
          {errors?.driver_class?.type === "required" && <p className="error_p">Select the class</p>}
        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Issue Date <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={licenceDetails.issue_date} type="date"
            {...register("issue_date", {
              required: true,
            })} />
          {errors?.issue_date?.type === "required" && <p className="error_p">Date is required</p>}
        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Vaild Upto <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={licenceDetails.valid_upto} type="date"
            {...register("valid_upto", {
              required: true,
            })} />
          {errors?.valid_upto?.type === "required" && <p className="error_p">Field is required</p>}
        </Form.Group>
        <Form.Group className="form-group" controlId="">
          <Form.Label>Authority <sup><i class="bi bi-asterisk my-icon"></i></sup></Form.Label>
          <Form.Control defaultValue={licenceDetails.authority} type="text"
            {...register("authority", {
              required: true,
            })} />
          {errors?.authority?.type === "required" && <p className="error_p">Authority is required</p>}
        </Form.Group>

        <div style={{ clear: "both" }}></div>
        <div>
          <button type="submit" className='mt-3 btn-next '>
            <h1><i className="bi bi-arrow-right-square-fill" ></i></h1>
          </button>
          <button className='mt-3 btn-back ' onClick={BackStep} >
            <h1><i className="bi bi-arrow-left-square-fill"></i></h1>
          </button>
        </div>
        <div style={{ clear: "both" }}></div>
      </Form>
    </div>
  )
}

export default LicenceDetails;