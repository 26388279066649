import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Builty from './Builty'
import SimpleBackdrop from '../../common/Spinner/Spinner'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { BASE_URL } from '../../../config'

const BuiltyModal = (props) => {
  const firmDetails = useSelector(state => state?.loginDetail?.firmDetails);
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [consignerGst, setConsignerGst] = useState();
  const [consigneeGst, setConsigneeGst] = useState();


  useEffect(() => {
    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    };
    const Consignerquery = {
      query: `query($firm_id:ID,$search:String){
       firmConsigner(firm_id:$firm_id,search:$search)
       {
         consigner_gstin
       }
     }`,
      variables: {
        firm_id: firmDetails?.firm_id,
        search: props?.Consigner
      }
    }
    const Consigneequery = {
      query: `query($firm_id:ID,$search:String){
    firmConsignee(firm_id:$firm_id,search:$search)
    {
      consignee_gstin
    }
  }`,
      variables: {
        firm_id: firmDetails?.firm_id,
        search: props?.Consignee
      }
    }

    //==== query to get Consigner details for builty =======
    if (props?.Consigner && props?.Consignee) {

      axios.post(BASE_URL, Consignerquery, { headers })
        .then((response) => {
          setConsignerGst(response.data?.data.firmConsigner?.[0].consigner_gstin);
        })
        .catch((error) => {
        })

      //==== query to get Consigner details for builty =======
      axios.post(BASE_URL, Consigneequery, { headers })
        .then((response) => {
          setConsigneeGst(response.data?.data.firmConsignee?.[0].consignee_gstin);
        })
        .catch((error) => {
        })
    }
  }, [props?.Consignee, props?.Consigner, firmDetails?.firm_id, bearerToken]);
  return (
    <>
      <Modal show={props?.modalHandler} size="lg" scrollable={true}>
        <Modal.Header
          className="mb-4"
          style={{
            backgroundColor: "#10233c",
            color: "white",
            height: "40px",
            textAlign: "center",
          }}
        >
          <h4 className=" my-0 m-auto">Builty</h4>
        </Modal.Header>


        {consigneeGst && consignerGst
          ? (
            <Modal.Body>
              <Builty
                bags={props?.bags}
                truck_no={props?.truck_no}
                trip_date={props?.trip_date}
                Consigner={props?.Consigner}
                Destination={props?.Destination}
                source={props?.source}
                Consignee={props?.Consignee}
                Lr={props?.Lr}
                invoice_no={props?.invoice_no}
                product={props?.product}
                consignerGst={consignerGst}
                consigneeGst={consigneeGst}

              />
            </Modal.Body>
          )
          : (
            <div className="m-5 p-5 ">
              <SimpleBackdrop />
            </div>
          )}
        <Modal.Footer>
          <Button variant="dark" onClick={() => props?.modalAction(false) && setConsigneeGst() && setConsignerGst()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BuiltyModal