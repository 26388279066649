import React from 'react'
import { Modal } from 'react-bootstrap'
import Loader from "react-js-loader";

const FirmDocModal = ({ show, handleFirmDocModal, url }) => {
    console.log("url", url)
    return (
        <>
            <Modal fullscreen show={show} onHide={() => handleFirmDocModal(false)}>
                <Modal.Header closeButton>
                    <h3>Firm Document</h3>
                </Modal.Header>
                <Modal.Body>
                    {url?.endsWith(".pdf") ? (
                        <embed className="w-100 h-100" src={url} />
                    ) : (
                        <div className="d-flex align-items-center justify-content-center pt-2 no_data-img">
                            <embed width="50%" src={url} />
                        </div>
                    )}


                </Modal.Body>
            </Modal>
        </>
    )
}

export default FirmDocModal