import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SimpleBackdrop from "../../common/Spinner/Spinner";
import { BASE_URL } from "../../../config";
import CustomizedSnackbars from "../../common/Popup/Snackbar";
import BuiltyModal from "../Builty/BuiltyModal";
import Loader from "../Loader/Loader";

const InvoiceModal = (props) => {
  const bearerToken = useSelector((state) => state?.loginDetail?.bearerToken);
  const [invoiceContent, setInvoiceContent] = useState();
  const [truckTrip, setTruckTrip] = useState();
  const [toast1Show, setToast1Show] = useState(false);
  const [toast2Show, setToast2Show] = useState(false);
  const [toast3Show, setToast3Show] = useState(false);
  const [payCheck, setPayCheck] = useState(false);
  const [settleCheck, setSettleCheck] = useState(false);
  const [shortageCheck, setShortageCheck] = useState(false);
  const [invoicePayment, setInvoicePayment] = useState();
  const [totalPaid, setTotalPaid] = useState();
  const [type, setType] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false)
  const [shortageAmount, setShortageAmount] = useState(0)
  const [payAmount, setPayAmount] = useState(0)
  const [payShortage, setPayShortage] = useState()
  const headers = {
    Authorization: `Bearer ${bearerToken} `,
    "Content-Type": "application/json",
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    const query = {
      query: `query($trip_id:ID!){
                tripInvoice(trip_id:$trip_id) {
                    trip_id
                    trip_invoice_id
                    package_quantity
                    total_weight
                    rate
                    freight
                    total_amount
                    remaining
                    lr_number
                    shortage_amount
                  }
              } `,
      variables: {
        trip_id: props?.tripId,
      },
    };

    const query2 = {
      query: `query($trip_id:ID){
                truckTrip(trip_id:$trip_id){
                  trip_date
                  trip_consigner
                  trip_consignee
                  trip_product
                  trip_source
                  trip_destination
                  trip_invoice_number
                  truck{
                    truck_number
                  }
                }
              }`,
      variables: {
        trip_id: props?.tripId,
      },
    };
    // ======= query to get Trip Invoice ========

    axios
      .post(BASE_URL, query, { headers })
      .then((response) => {
        setInvoiceContent(response?.data?.data.tripInvoice?.[0]);

        const query3 = {
          query: `query($trip_invoice_id:ID!){
                        tripInvoicePayment(trip_invoice_id:$trip_invoice_id){
                            amount_paid
                        }
                    }`,
          variables: {
            trip_invoice_id:
              response?.data?.data.tripInvoice?.[0]?.trip_invoice_id,
          },
        };
        axios
          .post(BASE_URL, query3, { headers })
          .then((response) => {
            setInvoicePayment(response.data?.data.tripInvoicePayment);
          })
          .catch((error) => { });
      })
      .catch((error) => []);

    // ======= query to get Trip details ========

    axios
      .post(BASE_URL, query2, { headers })
      .then((response) => {
        setTruckTrip(response?.data.data.truckTrip?.[0]);
      })
      .catch((error) => []);
  }, [props]);

  useEffect(() => {
    if (!payCheck) {
      setValue("amount_paid", null);
      setToast1Show(false)
    }
  }, [payCheck]);

  const onSubmit = (data) => {
    // ==== Mutation to update trip ==========
    setLoading(true)
    const query1 = {
      query: `mutation(
                        $trip_invoice_id:ID!,
                        $trip_id:ID!,
                        $amount_paid:Int
                        $shortage_status:Boolean
                        $shortage_amount:Int
                        $shortage_remark:String){
                  updateTrip(
                        trip_invoice_id:$trip_invoice_id,
                        trip_id:$trip_id,
                        amount_paid:$amount_paid
                        shortage_status:$shortage_status
                        shortage_amount:$shortage_amount
                        shortage_remark:$shortage_remark
            ) {
              message
              type
            }
          }`,
      variables: {
        trip_id: +props?.tripId,
        trip_invoice_id: invoiceContent.trip_invoice_id,
        amount_paid: +data.amount_paid,
        shortage_status: data?.shortage_status,
        shortage_amount: +data?.shortage_amount,
        shortage_remark: data?.shortage_remark
      },
    };

    // ========= Mutation to Update_invoice ==============

    const query2 = {
      query: `mutation( $trip_id: ID! , $trip_invoice_id: ID! ,$amount_paid: Int ,$settle_status:Boolean , $settle_remark:String) {
                       updateInvoice(
                            trip_invoice_id:$trip_invoice_id,
                            trip_id:$trip_id,
                            amount_paid:$amount_paid
                            settle_status:$settle_status
                            settle_remark:$settle_remark
                                    ) { 
                                        message
                                        type
                                        error {
                                            message
                                                trip_invoice_id
                                                amount_paid
                                       }
                        }
                    }`,
      variables: {
        trip_id: +props?.tripId,
        trip_invoice_id: invoiceContent.trip_invoice_id,
        amount_paid: +data?.amount_paid,
        settle_status: settleCheck,
        settle_remark: data?.settle_remark
      },
    };

    {
      props?.updateType == "update_trip" &&
        axios
          .post(BASE_URL, query1, { headers })
          .then((response) => {
            setLoading(false)
            setMessage(response.data?.data.updateTrip.message);
            setType(response.data?.data.updateTrip.type);
            setToast2Show(true);
          })
          .catch((error) => { setLoading(false) });
    }

    {
      props?.updateType == "update_invoice" &&
        axios
          .post(BASE_URL, query2, { headers })
          .then((response) => {
            setMessage(response.data?.data.updateInvoice.message);
            setType(response.data?.data.updateInvoice.type);
            setToast2Show(true);
          })
          .catch((error) => { });
    }
  };

  const handleShortageInput = (e) => {
    const result = e.target.value.replace(/[^0-9]/gi, "");
    setShortageAmount(+result)
    setValue("shortage_amount", +result);
    {
      +e.target.value > +invoiceContent?.remaining
        ? setToast3Show(true)
        : setToast3Show(false);
    }
  }

  const handleAmountInput = (e) => {
    // === to allow only Numbers in input field ===
    const result = e.target.value.replace(/[^0-9]/gi, "");

    setPayAmount(+result)
    setValue("amount_paid", +result);
    {
      +e.target.value > +invoiceContent.remaining
        ? setToast1Show(true)
        : setToast1Show(false);
    }
  };

  useEffect(() => {
    setTotalPaid(
      invoicePayment
        ?.map((p) => +p.amount_paid)
        .reduce((pre, curr) => pre + curr, 0)
    );
  }, [invoicePayment]);

  if (toast2Show) {
    setTimeout(() => {
      setToast2Show(false);
      props.modalAction(false);
    }, 4000);
  }
  useEffect(() => {
    setValue('shortage_status', shortageCheck);
    if (shortageCheck == false) {
      setShortageAmount(null)
      setValue("shortage_amount", null);
      setToast3Show(false)
    }
  }, [shortageCheck])


  useEffect(() => {
    setPayShortage((payAmount) + (shortageAmount))
  }, [payAmount, shortageAmount])
  useEffect(() => { }, [payCheck, props]);
  return (
    <>
      {props?.buttonType == "builty" ? (
        <>
          {props?.modalHandler && (
            <BuiltyModal
              modalHandler={props?.modalHandler}
              modalAction={props?.modalAction}
              bags={invoiceContent?.package_quantity}
              truck_no={truckTrip?.truck?.truck_number}
              trip_date={truckTrip?.trip_date}
              Consigner={truckTrip?.trip_consigner}
              Destination={truckTrip?.trip_destination}
              source={truckTrip?.trip_source}
              Consignee={truckTrip?.trip_consignee}
              Lr={invoiceContent?.lr_number}
              invoice_no={truckTrip?.trip_invoice_number}
              product={truckTrip?.trip_product}
            />
          )}
        </>
      ) : (
        <>
          {props?.modalHandler && (
            <Modal
              show={props?.modalHandler}
              size="xl"
              scrollable={true}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header

                className="mb-4 "
                style={{
                  backgroundColor: "#10233c",
                  color: "white",
                  height: "40px",
                  textAlign: "center",
                }}
              >
                {props?.updateType == "update_trip" ? (
                  <h4 className=" my-0 m-auto">Close Trip</h4>
                ) : (
                  <h4 className=" my-0 m-auto">Update Invoice</h4>
                )}
              </Modal.Header>
              <Modal.Body>
                {invoicePayment ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="form-group">
                      <Form.Label>Trip ID</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={invoiceContent?.trip_id}
                        type="text"
                      />
                    </Form.Group>

                    <Form.Group className="form-group" hidden={(invoiceContent?.lr_number) ? false : true}>
                      <Form.Label>LR Number</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={invoiceContent?.lr_number}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Package Qty.</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={invoiceContent?.package_quantity}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group" hidden={(invoiceContent?.total_weight) ? false : true}>
                      <Form.Label>Total Weight</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={invoiceContent?.total_weight}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Consigner</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={truckTrip?.trip_consigner}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Consignee</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={truckTrip?.trip_consignee}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Source</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={truckTrip?.trip_source}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Destination</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={truckTrip?.trip_destination}
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Product</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={truckTrip?.trip_product}
                        type="text"
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Freight</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={invoiceContent?.freight}
                        type="text"
                      />
                    </Form.Group>


                    <Form.Group className="form-group">
                      <Form.Label>Total Payable Amount</Form.Label>
                      <div className="d-flex">
                        <div className="py-2 px-3 form_icon_icon">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <input
                          className="form-control_icon diabledControl"
                          disabled
                          defaultValue={invoiceContent?.total_amount}
                          type="text"
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label>Remaining Amount</Form.Label>
                      <div className="d-flex">
                        <div className="py-2 px-3 form_icon_icon">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <input
                          disabled
                          className="form-control_icon diabledControl"
                          defaultValue={invoiceContent?.remaining}
                          type="text"

                        />
                      </div>
                    </Form.Group>
                    {invoiceContent?.shortage_amount
                      &&
                      <Form.Group className="form-group">
                        <Form.Label>Shortage Amount</Form.Label>
                        <div className="d-flex">
                          <div className="py-2 px-3 form_icon">
                            <i className="bi bi-currency-rupee"></i>
                          </div>
                          <Form.Control disabled defaultValue={invoiceContent?.shortage_amount}
                            type="text"
                          />
                        </div>
                      </Form.Group>
                    }

                    <Form.Group className="form-group">
                      <Form.Label>Paid Amount</Form.Label>
                      <div className="d-flex">
                        <div className="py-2 px-3 form_icon_icon">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <input className="form-control_icon diabledControl" disabled defaultValue={totalPaid} type="text" />
                        {/* <Form.Control
                          disabled
                          defaultValue={totalPaid}
                          type="text"
                        /> */}
                      </div>
                    </Form.Group>
                    <div style={{ clear: "both" }}></div>

                    {props?.updateType === "update_invoice" && (
                      <>
                        <div className="mb-4 mx-3">
                          <Form.Group>
                            <Form.Check
                              label=" &nbsp;&nbsp;Settle Trip ? "
                              type="switch"
                              checked={settleCheck ? true : false}
                              onChange={() => {
                                settleCheck == false
                                  ? setSettleCheck(true)
                                  : setSettleCheck(false);
                              }}
                            />
                          </Form.Group>
                        </div>
                        {settleCheck && (
                          <>
                            <Form.Group className="form-group">
                              <Form.Label>Remark</Form.Label>
                              <Form.Control
                                type="text"
                                {...register("settle_remark", {
                                  required: true
                                })}
                              />
                              {errors?.settle_remark?.type === "required" && (<p className="error_p"> Remark is required </p>)}
                            </Form.Group>
                          </>
                        )}
                      </>
                    )}
                    {props?.updateType == "update_trip" && (
                      <div className="mb-1 mx-3">
                        <Form.Group>
                          <Form.Label> </Form.Label>
                          <Form.Check
                            label=" &nbsp;&nbsp;Shortage ? "
                            type="switch"
                            checked={shortageCheck ? true : false}
                            onChange={() => {
                              shortageCheck == false
                                ? setShortageCheck(true)
                                : setShortageCheck(false);
                            }}

                          />
                        </Form.Group>
                      </div>
                    )}
                    {shortageCheck && (
                      <>
                        <Form.Group className="form-group">
                          <Form.Label>Shortage Amount</Form.Label>
                          <Form.Control
                            onChangeCapture={handleShortageInput}
                            type="number"
                            {...register("shortage_amount", {
                              required: true,
                            })}
                          />
                          {errors?.shortage_amount?.type === "required" && (<p className="error_p"> Shortage amount is required </p>)}
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Remark</Form.Label>
                          <Form.Control
                            type="text"
                            {...register("shortage_remark", {
                              required: true,
                            })}
                          />
                          {errors?.shortage_remark?.type === "required" && (
                            <p className="error_p">
                              Remark is required
                            </p>
                          )}
                        </Form.Group>
                      </>
                    )}
                    <div style={{ clear: "both" }}></div>

                    {props?.updateType == "update_trip" ? (
                      <>
                        <div className="mb-4 mx-3">
                          <Form.Group>
                            <Form.Check
                              label=" &nbsp;&nbsp;Close Trip with Payment ? "
                              type="switch"
                              checked={payCheck ? true : false}
                              onChange={() => {
                                payCheck == false
                                  ? setPayCheck(true)
                                  : setPayCheck(false);
                              }}
                            />
                          </Form.Group>
                        </div>

                        {payCheck && (
                          <Form.Group className="form-group">
                            <Form.Label>Pay</Form.Label>
                            <div className="d-flex w-100">
                              <div className="py-2 px-3 form_icon_icon">
                                <i className="bi bi-currency-rupee"></i>
                              </div>
                              <input
                                className="form-control_icon"
                                onChangeCapture={handleAmountInput}
                                type="text"
                                {...register("amount_paid", {
                                  required: true,
                                })}
                              />
                            </div>
                            {errors?.amount_paid?.type === "required" && (
                              <p className="error_p">
                                Amount to Pay is required
                              </p>
                            )}
                          </Form.Group>
                        )}
                      </>
                    ) : (
                      !settleCheck && (
                        <Form.Group className="form-group ">
                          <Form.Label>Pay</Form.Label>
                          <div className="d-flex w-100 ">
                            <div className="py-2 px-3 form_icon_icon">
                              <i className="bi bi-currency-rupee"></i>
                            </div>
                            <input
                              className="form-control_icon"
                              onChangeCapture={handleAmountInput}
                              type="text"
                              {...register("amount_paid", {
                                required: true,
                              })}
                            />
                          </div>
                          {errors?.amount_paid?.type === "required" && (
                            <p className="error_p">Amount to Pay is required</p>
                          )}
                        </Form.Group>
                      )
                    )}

                    <div style={{ clear: "both" }}></div>

                    {toast1Show && (
                      <div
                        className=" alert alert-danger d-flex justify-content-center"
                        role="alert"
                      >
                        Paying more than Remaining amount
                      </div>
                    )}
                    {
                      payShortage > invoiceContent?.remaining && shortageCheck && payCheck ? <div
                        className=" alert alert-danger d-flex justify-content-center"
                        role="alert"
                      >
                        Shortage and Paying can not be  more than Remaining amount
                      </div> : ""
                    }
                    {toast3Show && (
                      <div
                        className=" alert alert-danger d-flex justify-content-center"
                        role="alert"
                      >
                        Shortage can not be  more than Remaining amount
                      </div>
                    )}
                    {toast2Show && (
                      <CustomizedSnackbars
                        show={toast2Show}
                        messageType={type}
                        message={message}
                      />
                    )}

                    <Button
                      className="mx-3 px-3 "
                      variant="success"
                      type="submit"
                      disabled={toast1Show || toast3Show || (payShortage > invoiceContent?.remaining)}
                    >
                      {loading ? <div className="d-flex"><Loader /><span>{" "}Updating...</span></div> : <>Update</>}
                    </Button>
                  </Form>
                ) : (
                  <div className="m-5 p-5 ">
                    <SimpleBackdrop />
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <button
                  className="btn btn-dark"
                  onClick={() => props?.modalAction(false)}
                >
                  close
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default InvoiceModal;
